@import 'Select/assets/theme';

.headerContainer {
  display: flex;
  flex-direction: column;
  background-color: $white;

  .header {
    max-width: none;
  }
}

.container {
  display: flex;
  flex-direction: column;
  overflow: auto; // for infinity load to works correctly

  .contentWrapper {
    background-color: $white;

    &:nth-child(even) {
      background-color: $select-light-grey-10;
    }

    .content {
      display: flex;
      padding: $standard-padding * 6 0;

      @media (max-width: $tablet-screen-max-content) {
        flex-direction: column;
      }

      .mobileDetails {
        display: none;

        @media (max-width: $tablet-screen-max-content) {
          display: flex;
          margin-bottom: 24px;
        }
      }

      .profileWrapper {
        display: flex;
        flex: 1 25%;
        min-width: 330px;
        flex-direction: column;
        @include rowGap($standard-padding * 6);

        @media (max-width: $small-desktop-screen-max-content) {
          min-width: 280px;
          flex: 1;
        }
      }

      .detailsContainer {
        display: flex;
        flex: 1 75%;
        flex-direction: column;
        padding-left: $standard-padding * 6;
        overflow-x: hidden;
        overflow-y: hidden;
        @include rowGap(24px);

        @media (max-width: $tablet-screen-max-content) {
          padding-left: 0;
          padding-top: $standard-padding * 6;
          overflow-x: unset;
          overflow-y: unset;
        }
      }
    }

    .loadingWrapper {
      display: flex;
      flex: 1;

      @media (max-width: $tablet-screen-max-content) {
        flex-direction: column;
      }

      .bonaFidesLoadingWrapper {
        display: flex;
        flex-direction: column;
        @include rowGap($standard-padding * 2);

        .bonaFidesLoading {
          height: 18px;
          width: 90%;
        }
      }
    }

    .noResultWrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: $standard-padding * 10 0;
      text-align: center;

      .noResultTitle {
        font-weight: 700;
        font-size: 48px;
        line-height: 62px;
        letter-spacing: -0.96px;
        color: $select-black;
      }

      .noResultDesc {
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
        color: $select-black;
        text-align: center;
        margin-top: $standard-padding * 4;

        a {
          color: $select-red;
        }
      }
    }

    .recommendWrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: $standard-padding * 6 0 $standard-padding * 10;
      background: $white;

      @media (max-width: $tablet-screen-max-content) {
        padding: $standard-padding * 2 0 $standard-padding * 2;
      }

      .trustLabel {
        text-align: center;
        width: 80%;
        font-weight: 700;
        font-size: 36px;
        letter-spacing: -0.02em;
        color: $select-black;
        border-bottom: 1px solid $select-red;
        line-height: 0.1em;
        margin: 20px 0;

        @media (max-width: $small-desktop-screen-max-content) {
          line-height: unset;
          font-size: 24px;
          border-bottom: none;
          width: 100%;
          margin: 16px 0;
        }

        .label {
          background: $white;
          padding: 0 10px;

          @media (max-width: $small-desktop-screen-max-content) {
            border-bottom: 1px solid $select-red;
            padding-bottom: 8px;
          }
        }
      }
    }
  }
}
