@import 'CORDS/assets/theme';
@import 'assets/theme';
@import 'SomeoneHealth/assets/theme';
@import 'Ease/assets/theme';
@import 'Recharge/assets/theme';
@import 'Select/assets/theme';

.radio {
  display: flex;
  flex-wrap: wrap;

  &.vertical {
    flex-direction: column;

    .button {
      width: 100%;
    }
  }
}

.default {
  background-color: transparent;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 16px;
  border-radius: 8px;
  margin-top: 8px;

  &.error {
    border: 1px solid #fc8468;
    margin-bottom: 0;
  }

  &.noSpacing {
    margin-bottom: 0;
  }

  input {
    &:checked + label,
    &:active + label {
      color: #3f52ff;
      font-weight: 600;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  label {
    background-color: transparent;
    color: #343434;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    text-align: center;
    transition: all 0.2s cubic-bezier(0.35, 0.1, 0.25, 1);
    margin-left: 16px;
    flex: 1;
    display: flex;
    justify-content: left;
    align-items: center;

    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }

  @media (max-width: ($medium-screen-min-width - 1)) {
    label {
      flex: 1 0 auto;
    }
  }
}

.button {
  display: flex;
  flex-wrap: wrap;
  border-radius: $standard-padding;
  margin-bottom: $standard-padding;

  @media only screen and (max-width: $medium-screen-min-width) {
    margin-bottom: 0;
    justify-content: center;
  }

  &:not(:last-child) {
    margin-right: 24px;

    @media only screen and (max-width: $medium-screen-min-width) {
      margin-right: 16px;
    }
  }

  &.error {
    label {
      border: 1px solid #fc8468;
      border-radius: $standard-padding * 2;
      margin-bottom: 0;
    }
  }

  &.noSpacing {
    margin-bottom: 0;
  }

  input {
    display: none;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    width: 1px;
    border: 0;
    overflow: hidden;

    &:checked + label {
      background-color: #2a4670;
      color: #ffffff;

      &:hover {
        opacity: 0.8;
      }
    }

    &:active + label {
      background-color: #2a4670cc;
      color: #ffffff;
    }
  }

  label {
    background-color: #0085bd30;
    color: #343434;
    font-size: 12px;
    min-width: 72px;
    max-width: 480px;
    font-weight: 600;
    line-height: 16px;
    text-align: center;
    padding: 10px 12px;
    border-radius: 24px;
    transition: all 0.2s cubic-bezier(0.35, 0.1, 0.25, 1);
    margin-right: 40px;
    flex: 1;
    display: flex;
    justify-content: space-around;
    align-items: center;

    span {
      font-size: 11px;
      font-weight: 700;
      padding: 3px;
      color: white;
      background-color: #3f52ff;
      border-radius: 100%;
      margin-left: 8px;
    }

    &:hover {
      cursor: pointer;
      background-color: #e8e9ed;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }
}

.label {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 18px;
  color: #192a3e;
  margin: 8px 0;
  cursor: pointer;
}

$color1: #fff;
$color2: #3f52ff;

.radioBtn {
  display: flex;
  align-items: center;
  input[type='radio'] {
    position: absolute;
    opacity: 0;
    + .label {
      &:before {
        content: '';
        background: $color1;
        border-radius: 100%;
        border: 1px solid #828282;
        display: flex;
        min-width: 20px;
        height: 20px;
        position: relative;
        margin-right: 16px;
        vertical-align: top;
        cursor: pointer;
        text-align: center;
        transition: all 250ms ease;
      }
    }
    &:checked {
      + .label {
        &:before {
          background-color: $color2;
          box-shadow: inset 0 0 0 4px $color1;
          border: 1px solid $color2;
        }
      }
    }
    &:focus {
      + .label {
        &:before {
          outline: none;
          border-color: $color2;
        }
      }
    }
    &:disabled {
      + .label {
        &:before {
          box-shadow: inset 0 0 0 4px $color1;
          border-color: darken($color1, 25%);
          background: darken($color1, 25%);
        }
      }
    }
    + .label {
      &:empty {
        &:before {
          margin-right: 0;
        }
      }
    }
  }
}

:global(.cords-assessment-question) {
  .button {
    label {
      background-color: $cords-light-yellow;
      color: $cords-black;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;

      .checkedIcon {
        display: none;
      }
    }

    input {
      display: none;

      &:checked + label {
        background-color: $cords-yellow;
        font-weight: 600;
        color: #1b365d;
        font-size: 16px;
        line-height: 19px;
      }

      &:active + label {
        background-color: $cords-yellow;
        color: #1b365d;
      }
    }
  }
}

:global(.someone-health-theme) {
  .button {
    input {
      &:checked + label {
        background-color: $someone-health-pink;
        color: #ffffff;
      }

      &:active + label {
        background-color: $someone-health-pink;
        color: #ffffff;
      }
    }

    .checkedIcon {
      background-color: $someone-health-green;
    }

    label {
      background-color: $someone-health-light-pink;
    }
  }
  .radioBtn {
    input[type='radio'] {
      &:checked {
        + .label {
          &:before {
            background-color: $someone-health-pink;
            border: 1px solid $someone-health-pink;
          }
        }
      }
    }
  }
}

:global(.ease-theme) {
  .button {
    input {
      &:checked + label {
        background-color: $ease-green;
        color: $ease-dark-grey;
      }

      &:active + label {
        background-color: $ease-green;
        color: $ease-dark-grey;
      }
    }

    .checkedIcon {
      background-color: $ease-green;
      color: $ease-dark-grey;
      font-size: 14px;
    }

    label {
      background-color: $ease-green;
      color: $ease-dark-grey;
    }
  }
  .radioBtn {
    input[type='radio'] {
      &:checked {
        + .label {
          &:before {
            background-color: $ease-green;
            border: 1px solid $ease-green;
          }
        }
      }
    }
  }
}

:global(.recharge-theme) {
  .label {
    font-weight: 600;
    color: $recharge-black;
  }

  .button {
    input {
      &:checked + label {
        background-color: $recharge-purple;
        color: $white;
      }

      &:active + label {
        background-color: $recharge-purple;
        color: $white;
      }
    }

    .checkedIcon {
      background-color: $recharge-purple;
      color: $white;
      font-size: 14px;
    }

    label {
      background-color: $recharge-light-purple;
      color: $recharge-black;
    }
  }
  .radioBtn {
    input[type='radio'] {
      + .label {
        font-size: 13px;
      }

      &:checked {
        + .label {
          &:before {
            background-color: $recharge-purple;
            border: 1px solid $recharge-purple;
          }
        }
      }
    }
  }
}

:global(.select-theme) {
  .label {
    font-weight: 600;
    color: $select-black;
  }

  .button {
    input {
      &:checked + label {
        background-color: $select-red;
        color: $select-black;
      }

      &:active + label {
        background-color: $select-red;
        color: $select-black;
      }
    }

    .checkedIcon {
      background-color: $select-red;
      color: $select-black;
      font-size: 14px;
    }

    label {
      background-color: $select-green;
      color: $select-black;
    }
  }
  .radioBtn {
    input[type='radio'] {
      + .label {
        font-size: 13px;
      }

      &:checked {
        + .label {
          &:before {
            background-color: $select-red;
            border: 1px solid $select-red;
          }
        }
      }
    }
  }
}
