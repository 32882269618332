@import 'Ease/assets/theme';
@import 'Recharge/assets/theme';
@import 'Select/assets/theme';
@import 'assets/theme';

.container {
  display: flex;
  justify-content: center;
  align-items: flex-end;

  .progress {
    width: 100%;
    background: #ccc;
    border-radius: 6px;
    transition: width 1s ease;
    height: 4px;
  }

  .bar {
    height: 4px;
    border-radius: 6px;
    background: $cta;
    transition: width 1s ease;
  }
}

:global(.ease-theme) {
  .container .bar {
    background: $ease-green;
  }
}

:global(.recharge-theme) {
  .container .bar {
    background: $recharge-purple;
  }
}

:global(.select-theme) {
  .container .bar {
    background: $select-red;
  }
}
