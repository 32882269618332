@import 'helm/assets/theme';

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 12px;
  color: $helm-blue;
  background-color: $calendarPill;
  border-radius: 30px;
  font-family: $primary-font-stack-bold !important;
  font-size: 12px;
  cursor: pointer;
}

.newHelmContainer {
  @extend .container;
  background-color: $helm-lighter-yellow;
}

.darkYellowContainer {
  @extend .container;
  background-color: $helm-yellow;
}
