@import 'assets/theme';
@import 'HealthBright/assets/theme';

.loadingContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  background-color: $white;
  position: fixed;
  z-index: 2;
}

.box {
  display: flex;
  flex-direction: column;
  margin: 28px 100px 57px;
  background-color: $health-bright-light-yellow;
  padding: 16px;
  border-radius: 8px;

  @media (max-width: $medium-desktop-screen-max-content) {
    padding: 16px 50px;
    margin: 28px 50px 57px;
  }

  @media (max-width: $medium-small-screen-min-width) {
    margin: 28px 0;
    padding: 16px;
  }

  .avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 24px 0 32px;

    img {
      height: 150px;
      min-width: 150px;
      border-radius: 50%;

      @media (max-width: $medium-small-screen-min-width) {
        height: 100px;
        min-width: 100px;
      }
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    max-width: 1024px;
    margin: 0 auto;

    .badge {
      display: flex;
      align-items: center;
      border-radius: 4px;
      padding: 8px 16px;
      background-color: $health-bright-tidal-green;
      color: $white;
      font-size: 14px;
      width: max-content;
      font-weight: 400;
    }

    .title {
      display: flex;
      font-family: $quincyCF;
      font-size: 32px;
      font-weight: 400;
      line-height: 36px;
      color: $health-bright-blue800;
      padding-top: 20px;

      @media (max-width: $tablet-screen-max-content) {
        font-size: 24px;
      }
    }

    .desc {
      font-weight: 300;
      font-size: 18px;
      line-height: 23px;
      padding-top: 28px;

      @media (max-width: $tablet-screen-max-content) {
        font-size: 16px;
      }

      .link {
        color: $colorBlue500;
        text-decoration: underline;
        font-weight: 400;
      }
    }
  }

  .closeButtonWrapper {
    display: flex;
    justify-content: center;
    padding: 48px 0 24px;

    @media (max-width: $medium-small-screen-min-width) {
      > div {
        min-width: auto;
        width: 100%;
      }
    }

    .closeButton {
      min-width: 358px;
      @media (max-width: $medium-small-screen-min-width) {
        min-width: unset;
      }
    }
  }
}
