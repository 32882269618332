@import 'HealthBright/assets/theme';

.container {
  display: flex;
  flex-direction: column;
  color: $health-bright-blue800;

  .label {
    font-size: 12px;
    line-height: 16px;
  }

  .serviceDetails {
    display: flex;
    flex-direction: column;
    margin-top: 12px;
    @include rowGap($standard-padding * 2);

    .details {
      display: flex;
      align-items: center;
      font-size: 18px;
      line-height: 21px;

      @media (max-width: $tablet-screen-max-content) {
        font-size: 14px;
        line-height: 18px;
      }

      .icon {
        font-size: 20px;
        padding: $standard-padding;
        background-color: $health-bright-light-yellow;
        border-radius: 50%;
        margin-right: 8px;

        @media (max-width: $tablet-screen-max-content) {
          font-size: 18px;
        }
      }
    }
  }
}
