@import 'helm/assets/theme';

.container {
  display: flex;
  flex-direction: column;
  margin: 0 8px;

  .selectButton {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 20px;
    border: 2px solid $white;
    box-sizing: border-box;
    letter-spacing: 0.1px;
    border-radius: 8px;
    cursor: pointer;
    color: $helm-yellow;
    font-weight: 700;
    font-size: 36px;
    line-height: 47px;
    user-select: none;
    min-width: 200px;

    @media (max-width: $tablet-screen-max-content) {
      font-size: 24px;
      line-height: 32px;
      min-width: 170px;
    }

    .icon {
      font-size: 40px;
      width: 30px;

      @media (max-width: $tablet-screen-max-content) {
        font-size: 30px;
      }
    }
  }

  .menuWrapper {
    display: flex;
    justify-content: flex-end;
    position: relative;
    width: 100%;
    z-index: 1;

    .menuBoxHide {
      position: absolute;
      width: 100%;
      background: #fff;
      border-radius: 12px;
      border: 2px solid $helm-blue;
      visibility: hidden;
      opacity: 0;
      transition: all 0.3s;
      transform: scaleY(0);
      transform-origin: top;

      .listBox {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 12px 40px;
        color: $helm-blue;
        transition: all 0.2s ease;
        cursor: pointer;
        position: relative;

        &:not(:last-child) {
          border-bottom: 1px dashed $helm-blue;
        }

        &:hover {
          color: #3f52ff;
        }

        .title {
          font-weight: 500;
          font-size: 24px;
          line-height: 32px;

          @media (max-width: $tablet-screen-max-content) {
            font-size: 18px;
          }
        }

        .desc {
          margin-top: 4px;
          font-weight: 500;
          font-size: 14px;
          line-height: 18px;
          color: $grey700;

          @media (max-width: $tablet-screen-max-content) {
            font-size: 12px;
          }
        }
      }
    }

    .menuBoxShow {
      @extend .menuBoxHide;
      visibility: visible;
      opacity: 1;
      transform: scaleY(1);
    }
  }
}
