@import 'assets/theme';
@import 'CORDS/assets/theme';

.container {
  margin-bottom: $standard-padding * 2;

  @media (max-width: $medium-small-screen-min-width) {
    margin-bottom: 0;
  }

  .stem {
    font-style: italic;
    font-size: 13px;
    font-weight: bold;
    color: #4b718e;
    white-space: pre-line;
  }

  .title {
    font-size: 18px;
    font-weight: bold;
    line-height: 22px;
    color: #343434;
    padding-bottom: 8px;

    @media (max-width: $medium-screen-min-width) {
      font-size: 16px;
      padding-bottom: 4px;
    }
  }
}

.cords {
  .stem {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    color: $cords-black;
    white-space: pre-line;
  }

  .title {
    font-size: 18px;
    font-weight: bold;
    line-height: 22px;
    color: #343434;
    padding-bottom: 8px;

    @media (max-width: $medium-screen-min-width) {
      font-size: 16px;
      padding-bottom: 4px;
    }
  }
}
