@import 'SomeoneHealth/assets/theme';

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 10px;
  color: $someone-health-blue;
  background-color: $calendarPill;
  border-radius: 8px;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  cursor: pointer;
  min-width: 120px;
}

.darkYellowContainer {
  @extend .container;
  background-color: $someone-health-light-green;
}
