@import 'HealthBright/assets/theme';

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;

  .header {
    color: $health-bright-blue800;
    font-size: 18px;
    font-weight: 400;
    line-height: 23px;
  }

  .inputLabel {
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
  }

  .customInput {
    transform: translateX(-$standard-padding);
    padding: 0 $standard-padding;
    color: $health-bright-blue800;
    margin-bottom: $standard-padding !important;
    font-weight: 400;
    font-size: 16px;
  }

  .customSelect {
    padding: $standard-padding * 2 0;
  }

  .selectInputLabel {
    @extend .inputLabel;
    margin-bottom: 0;
    font-size: 12px;

    &:focus {
      color: $health-bright-dark-green !important;
    }
  }

  .passwordRulesLabel {
    font-size: 12px;
    font-weight: 300;
    line-height: 16px;
    margin-top: -10px;
    color: $colorGrey800;
  }

  .passwordHint {
    padding: 0 $standard-padding * 4;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: $colorGrey700;
  }

  .passwordHintWithError {
    margin-top: 0;
  }

  .showPasswordButtonClass {
    &:hover {
      color: $health-bright-dark-blue !important;
      background-color: transparent !important;
    }
  }

  .ageConfirm {
    padding: 0 $standard-padding * 4;

    .checkBoxText {
      height: fit-content;
      display: inline-block;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      margin-top: $standard-padding;
      margin-bottom: $standard-padding * 3;
      color: $colorGrey700;

      a {
        text-decoration: underline;
        color: $colorBlue500;
      }
    }
  }
}
