@import 'SomeoneHealth/assets/theme';

.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-size: cover;
  min-height: 440px;
  max-height: 440px;
  background-image: url('~SomeoneHealth/assets/images/background/practitioner-profile-header.png');
  background-position: top;
  background-repeat: no-repeat;
  position: relative;
  z-index: 1;
  flex: 1 0 auto;

  .headerContent {
    display: flex;
    width: 100%;
    flex: initial;
  }

  .headerWrapper {
    display: flex;
    padding: 0 16px 24px;

    .shHeader {
      padding: 8px 0 !important;

      @media (max-width: $medium-large_screen-min-width) {
        padding: 8px 16px !important;
      }
    }
  }

  .contentWrapper {
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    position: relative;
    z-index: 3;
    height: calc(100vh - 100px);
    padding: 0;
  }
}
