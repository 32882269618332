@import 'assets/theme';

.container {
  height: max-content;
  position: relative;
  z-index: 1;
  background-size: cover;
  min-height: 440px;
  max-height: 440px;
  background-image: url('~SomeoneHealth/assets/images/background/practitioner-profile-header.png');
  background-position: top;
  background-repeat: no-repeat;

  .headerWrapper {
    display: flex;
    padding: 0 16px 24px;
  }

  .contentWrapper {
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    position: relative;
    z-index: 3;
  }
}
