@import 'HealthBright/assets/theme';

.container {
  display: flex;
  flex-direction: column;
  padding: 24px 0;
  background-color: $health-bright-dark-blue;
  color: $white;

  .content {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: $medium-desktop-screen-max-content) {
      flex-direction: column;
      align-items: unset;
      @include rowGap(24px);
    }
  }

  .filterWrapper {
    display: flex;
    flex: 1;
    align-items: center;

    &::-webkit-scrollbar {
      display: none;
    }

    @media (max-width: $medium-desktop-screen-max-content) {
      overflow-x: scroll;
      overflow-y: hidden;
      white-space: nowrap;
      margin: 0 -16px 24px;
      padding: 0 16px;
    }
  }

  .timezone {
    display: flex;
    align-items: center;
    max-width: 182px;
    text-align: end;

    @media (max-width: $medium-desktop-screen-max-content) {
      justify-content: flex-end;
      max-width: unset;
    }
  }

  .labelWrapper {
    display: flex;
    flex-direction: column;
    width: max-content;
    @include rowGap(4px);

    .title {
      font-weight: 400;
      font-size: 16px;
      line-height: 21px;
    }

    .desc {
      font-weight: 300;
      font-size: 14px;
      line-height: 21px;
    }
  }

  .fitFilterWrapper {
    display: flex;
    flex: 1;
    align-items: center;
    margin: 0 0 0 24px;
    position: static;
    @include columnGap(16px);

    .gender {
      max-width: 120px;
      width: 100%;

      @media (max-width: $medium-desktop-screen-max-content) {
        max-width: 100%;
      }
    }

    .days {
      @extend .gender;
      min-width: max-content;
    }

    .times {
      @extend .gender;
      max-width: 110px;
    }

    .deliveryMode {
      @extend .gender;
      max-width: 110px;
    }

    .language {
      @extend .gender;
      max-width: 140px;
    }

    .style {
      @extend .gender;
      max-width: 100px;
    }

    .fitReset {
      color: $white;
      font-size: 14px;
      line-height: 18px;
      text-decoration-line: underline;
      cursor: pointer;
      white-space: nowrap;
      margin-right: 16px;
      user-select: none;
    }

    .daysMenuWrapperClass {
      max-width: 200px;
      position: absolute;
      margin-top: 50px;
    }
  }
}
