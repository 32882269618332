@import 'helm/assets/theme';

.container {
  display: flex;
  flex-direction: column;
  max-width: 540px;
  margin-left: auto;
  margin-right: auto;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: $grey700;

  .header {
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    margin-bottom: $standard-padding * 4;

    .highlight {
      color: $helm-primary;
    }
    display: block;
  }

  .summaryRow {
    margin-top: $standard-padding * 5;
    width: 100%;
    display: flex;
    align-items: flex-start;

    .summaryLeftCol {
      width: 24%;
      text-align: right;
      padding-right: $standard-padding * 8;
      font-size: 14px;
      line-height: 21px;
      margin-top: $standard-padding;
      color: $grey500;
    }

    .summaryRightCol {
      width: 76%;
      font-weight: 600;
      font-size: 18px;
      line-height: 27px;
    }

    .summaryTextDescription {
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
    }

    .totalToPayTitle {
      align-self: center;
      width: 24%;
    }

    .totalToPay {
      align-self: center;
      width: 76%;
      margin-top: $standard-padding * -1;
      font-weight: 700;
      font-size: 28px;
      line-height: 42px;
      letter-spacing: -0.02em;
    }
  }

  .divider {
    width: 100%;
    margin: $standard-padding * 6 0 $standard-padding * 7 0;
    border-top: dashed 2px $grey700;
  }

  .bookingRuleErrorContainer {
    display: flex;
    flex-direction: row;
    padding: $standard-padding * 3 $standard-padding * 3.5;
    font-weight: 600;
    font-size: 18px;
    background: #fad1d1;
    border-radius: 4px;

    .icon {
      font-size: 20px;
      color: #d54141;
    }

    @media (max-width: $mobile-screen-max-content + 100px) {
      padding: $standard-padding * 2 $standard-padding * 2.5;
      font-size: 15px;
    }
  }

  .returnButton {
    align-self: center;
    margin-top: $standard-padding * 4;
    margin-bottom: $standard-padding * 8;
    font-size: 32px;

    @media (max-width: $mobile-screen-max-content + 100px) {
      font-size: 26px;
    }
  }

  .supportAndRules {
    margin-top: 30px;

    .supportAndRulesTitle {
      font-weight: 600;
      font-size: 18px;
      line-height: 27px;
    }

    .supportAndRulesDescription {
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      margin-top: $standard-padding * 1;
      margin-bottom: $standard-padding * 2;
    }

    .checkBox {
      vertical-align: top;

      input {
        outline: none;
        transform: scale(0.75);
        &:checked::after {
          display: inline-flex;
        }
      }
      margin-right: $standard-padding * 3;
    }

    .checkBoxText {
      height: fit-content;
      display: inline-block;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      margin-top: $standard-padding;
      margin-bottom: $standard-padding * 3;
    }
  }

  .submitButton {
    width: 100%;
    margin-top: $standard-padding * 2;
    margin-bottom: $standard-padding * 8;
  }

  @media screen and (max-width: $tablet-screen-max-content) {
    margin-top: $standard-padding * 10;
  }

  @media screen and (max-width: $mobile-screen-max-content) {
    margin-top: $standard-padding * 5;
    .header {
      margin-top: $standard-padding * 5;
    }

    .summaryRow {
      margin-top: $standard-padding * 5;
      width: 100%;
      display: flex;
      align-items: flex-start;

      .summaryLeftCol {
        padding-right: $standard-padding * 4;
        width: 30%;
        font-size: 12px;
        line-height: 24px;
      }

      .summaryRightCol {
        width: 70%;
        font-size: 16px;
        line-height: 24px;
      }

      .summaryTextDescription {
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
      }

      .totalToPayTitle {
        width: fit-content;
        padding-right: $standard-padding * 5;
      }

      .totalToPay {
        width: fit-content;
        margin-top: $standard-padding * -1;
        font-weight: 700;
        font-size: 28px;
        line-height: 42px;
        letter-spacing: -0.02em;
      }
    }
  }
}
