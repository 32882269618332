@import 'assets/theme';
@import 'Ease/assets/theme';
@import 'Recharge/assets/theme';
@import 'Select/assets/theme';

.container {
  display: flex;
  flex-direction: column;
  padding: $standard-padding * 3 $standard-padding * 4 0;

  &.active {
    background-color: #f5f6ff;
  }

  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: $standard-padding * 3;

    .iconContainer {
      display: flex;
      align-items: center;
      border: 1px solid $colorBlue1;
      border-radius: 50%;
      padding: 12px;
      margin-right: $standard-padding * 2.5;

      .icon {
        font-size: 20px;
        color: $colorBlue1;
      }

      &.completed {
        color: gray;
        border-color: gray;

        .icon {
          color: unset;
        }

        &.warning {
          color: #ef6a6a;
          border-color: #ef6a6a;
        }

        &.attended {
          color: #4bc27d;
          border-color: #4bc27d;
        }

        &.didNotAttended {
          color: #ff7d45;
          border-color: #ff7d45;
        }
      }
    }

    .textContainer {
      width: 100%;

      .label {
        color: $colorBlue1;
        font-size: 14px;
        font-weight: 700;
      }

      .text {
        color: #949aa2;
        font-size: 16px;
        font-weight: 600;

        strong {
          font-weight: 700;
          color: black;
        }

        a {
          color: $colorBlue1;
        }
      }

      .requestContainer {
        @include rowGap(4px);
      }

      .requestText {
        @extend .text;
        color: #414449;
        font-size: 13px;
        font-weight: 400;

        .rejected {
          color: #f15944;
          font-size: 14px;
          font-weight: 600;
        }
      }
      .notes {
        font-size: 14px;
        font-weight: 600;
        color: #414449;
      }

      &.marginLeft {
        @media (min-width: $medium-screen-min-width) {
          margin-left: 66px;
        }
      }
    }

    .actionsContainer {
      display: flex;
      align-items: center;

      .button {
        background: none;
        border: none;
        border-radius: 50%;
        padding: $standard-padding * 1.5;
        cursor: pointer;

        width: 44px;
        height: 44px;

        &:hover {
          background-color: #d9dcff;
        }

        .icon {
          color: #61666e;
          font-size: 20px;
        }
      }
    }
  }
}

:global(.ease-theme) {
  .container .row .actionsContainer .button {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0;
    transition: all 0.1s ease-in-out;
    color: $ease-dark-grey;

    &:hover {
      background-color: $ease-green;
    }
  }
}

:global(.recharge-theme) {
  .container .row .actionsContainer .button {
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.1s ease-in-out;

    .icon {
      color: $recharge-black;
    }

    &:hover {
      background-color: $recharge-light-blue;

      .icon {
        color: $blue500;
      }
    }
  }
}

:global(.select-theme) {
  .container {
    &.active {
      background-color: $select-light-grey-100;
    }

    .row {
      .iconContainer {
        border-color: $select-red;

        .icon {
          color: $select-red;
        }
      }

      .textContainer {
        .label {
          color: $select-black;
        }

        a {
          color: $select-red;
        }
      }

      .actionsContainer .button {
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 0.1s ease-in-out;

        .icon {
          color: $select-black;
        }

        &:hover {
          background-color: $select-beige;

          .icon {
            color: $select-red;
          }
        }
      }
    }
  }
}
