@import 'HealthBright/assets/theme';

.container {
  display: flex;
  flex-direction: column;
  font-family: $stolzl, sans-serif;
  min-height: 100vh;
  color: $health-bright-blue800;

  input,
  textarea,
  button {
    font-family: $stolzl, -apple-system, BlinkMacSystemFont, sans-serif;
  }

  .footer {
    height: 28px;

    a {
      color: $white;
      text-decoration: underline;
      font-size: 14px;
      line-height: 21px;
    }
  }
}
