@import 'HealthBright/assets/theme';

.summary {
  display: flex;
}

.index {
  min-width: 30px;
  height: 30px;
  background: $health-bright-light-yellow;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #465251;
}

.details {
  margin: 15px 0;
  padding-top: 20px;

  &.topSeparation {
    border-top: 1px dashed $health-bright-blue800;
  }

  &.bottomSeparation {
    border-bottom: 1px dashed $health-bright-blue800;
    padding-bottom: 24px;
  }
}

.item {
  display: flex;
  gap: 33px;
  padding: 10px 0;

  .label {
    flex: 1 1;
    font-weight: 300;
    font-size: 14px;
    line-height: 18px;
    color: $colorGrey500;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    text-align: right;

    @media screen and (max-width: $medium-large_screen-min-width) {
      font-weight: 600;
      font-size: 12px;
    }
  }

  .value {
    flex: 3 1;
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    color: $health-bright-blue800;
    min-width: 337px;

    @media screen and (max-width: $medium-large_screen-min-width) {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
    }

    @media screen and (max-width: $medium-small-screen-min-width) {
      min-width: unset;
    }
  }
}
