@import 'assets/theme';
@import 'HealthBright/assets/theme';

.container {
  width: 100%;
}

.title {
  color: #11211f;
  font-size: 18px;
  font-weight: 400;
  line-height: 23px;
}

.contentWrapper {
  gap: 50px;
}

.leftContent {
  width: 50%;

  @media screen and (max-width: ($tablet-screen-max-content + 1px)) and (min-width: $small-desktop-screen-max-content) {
    min-width: 577px;
  }

  @media (max-width: $tablet-screen-max-content) {
    width: calc(100vw - 32px);
    max-width: 577px;
  }

  .addressWrapper {
    gap: 20px;

    @media (max-width: $tablet-screen-max-content) {
      flex-direction: column;
      gap: 0;
    }

    > div {
      display: flex;
      flex-direction: column;
      flex: 1;
    }
  }
}

.rightContent {
  flex: 1;
  max-width: 483px;

  @media (max-width: $tablet-screen-max-content) {
    display: none;
  }
}

.addressForm {
  @media (max-width: $medium-small-screen-min-height) {
    display: block;
    margin-left: 0;
    width: 100%;
  }
}

.addressSuggestion {
  width: 100%;
}

.addressSuggestionMB {
  margin-top: 10px;

  @media (min-width: ($tablet-screen-max-content + 1px)) {
    display: none;
  }
}

.formItem {
  margin-top: 24px;
  margin-bottom: 4px;

  &.fieldError,
  .fieldError {
    font-size: 12px;
    color: #ff4d4f;

    input {
      border-bottom: 1px solid #ff4d4f !important;
    }
  }
}
