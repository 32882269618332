@import 'HealthBright/assets/theme';

.container {
  display: flex;
  align-items: center;
  @include columnGap(12px);
  flex-wrap: wrap;
}

.inLabel {
  margin: 0 12px 8px 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: $health-bright-blue800;
}

.tag {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  text-align: center;
  border-radius: 40px;
  padding: 8px 10px;
  margin-bottom: 8px;
  color: $health-bright-blue800;
  background: $health-bright-light-yellow;

  .callIcon {
    font-size: 20px;
    margin-right: 8px;

    @media (max-width: $tablet-screen-max-content) {
      margin-right: 0;
    }
  }
}

.selectedTag {
  @extend .tag;
  background: $health-bright-light-green;
}
