@import 'assets/theme/index';

.container {
  padding: 20px;

  @media (max-width: $medium-small-screen-min-width) {
    padding: 20px 12px;
  }

  .row {
    display: flex;
    justify-content: space-between;
  }

  .topRow {
    @extend .row;
    margin-bottom: 32px;

    @media (max-width: $medium-small-screen-min-width) {
      flex-direction: column-reverse;
    }

    .practice {
      display: flex;
      max-width: 45%;
      overflow: hidden;

      @media (max-width: $medium-small-screen-min-width) {
        max-width: 100%;
        margin-bottom: 16px;
      }

      .image {
        height: 80px;
        width: 80px;
        margin-right: 8px;
        border-radius: 16px;
      }

      .practiceDetails {
        display: flex;
        flex-direction: column;

        .title {
          font-size: 20px;
          font-weight: bold;
          line-height: 30px;
          color: #343434;
        }

        .description {
          font-size: 14px;
          font-weight: 600;
          line-height: 21px;
          color: #343434;
          opacity: 0.5;
        }
      }
    }

    .clinician {
      display: flex;
      max-width: 45%;
      overflow: hidden;

      @media (max-width: $medium-small-screen-min-width) {
        max-width: 100%;
      }

      .image {
        height: 80px;
        width: 80px;
        margin-right: 8px;
        border-radius: 50%;
      }

      .clinicianDetails {
        display: flex;
        flex-direction: column;

        .title {
          font-size: 20px;
          font-weight: bold;
          line-height: 30px;
          color: #343434;
        }

        .description {
          font-size: 14px;
          font-weight: 600;
          line-height: 21px;
          color: #343434;
          opacity: 0.5;
        }
      }
    }
  }

  .bottomRow {
    @extend .row;
    flex-direction: column;

    .childRow {
      display: flex;

      &:last-child {
        margin-top: 32px;
      }
    }

    .item {
      display: flex;
      flex-direction: column;

      &:not(:first-child) {
        flex: 1 1 25%;
        padding-left: 8px;
      }

      &:first-child {
        flex: 1 2 50%;
        max-width: 50%;
      }

      .label {
        margin-bottom: 8px;
        font-size: 14px;
        font-weight: 600;
        line-height: 21px;
        color: #343434;
      }

      .description {
        font-size: 12px;
        line-height: 16px;
        color: #343434;
        position: relative;
      }
    }
  }
}
