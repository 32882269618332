@import 'HealthBright/assets/theme';

.container {
  display: flex;
  flex-direction: column;
  color: $health-bright-blue800;

  .label {
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    font-weight: 400;
  }

  .bonaFideDetails {
    display: flex;
    flex-direction: column;
    margin-top: 12px;
    @include rowGap($standard-padding * 2);

    .details {
      display: flex;
      align-items: center;
      font-size: 16px;
      line-height: 21px;
      font-weight: 300;

      @media (max-width: $tablet-screen-max-content) {
        font-size: 14px;
        line-height: 18px;
      }

      .icon {
        font-size: 20px;
        padding: $standard-padding;
        background-color: $health-bright-light-yellow;
        border-radius: 50%;
        margin-right: 8px;

        @media (max-width: $tablet-screen-max-content) {
          font-size: 18px;
        }
      }

      .verifiedIcon {
        color: $pink;
        margin-left: 8px;
        font-size: 20px;
        cursor: pointer;
      }

      .tooltip {
        max-width: 230px;
        border-radius: 8px;
        line-height: 16px;
        font-size: 14px;
        padding: 12px;
        opacity: 0.95 !important;
      }
    }
  }
}
