@import 'assets/theme';

.question {
  font-size: 1.5rem;
  margin-bottom: $smp-standard-padding * 4;
}

.subtitle {
  font-size: 1rem;
}

.actionContainer {
  display: flex;
  flex-wrap: wrap;
  gap: $smp-standard-padding * 8;
  justify-content: space-between;
  margin-top: $smp-standard-padding * 8;

  @media screen and (max-width: 637px) {
    flex-direction: column;
  }

  .createButtons {
    display: flex;
    flex-wrap: wrap;
    gap: $smp-standard-padding * 8;
    justify-content: flex-end;

    @media screen and (max-width: 637px) {
      flex-direction: column;
    }
  }
}
