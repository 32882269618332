@import 'HealthBright/assets/theme';

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  height: 56px;
  width: 56px;
  cursor: pointer;
  position: fixed;
  z-index: 99;
  bottom: 20px;
  right: 24px;
  box-shadow: rgba(0, 0, 0, 0.1) 0 1px 4px, rgba(0, 0, 0, 0.2) 0 2px 12px;

  .icon {
    font-size: 28px;
    line-height: 38px;
  }
}
