@import 'assets/theme';
@import 'Ease/assets/theme';
@import 'Recharge/assets/theme';
@import 'Select/assets/theme';

.container {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;

  .avatarsContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    width: 600px;
    margin: 16px auto 40px;
    gap: 16px;

    @media only screen and (max-width: $medium-screen-min-width) {
      justify-content: space-between;
      width: 400px;
      margin: 8px auto 32px;
    }

    @media only screen and (max-width: $medium-small-screen-min-width) {
      width: 100%;
    }

    .avatarWrapper {
      width: 30%;
      max-width: 160px;

      @media only screen and (max-width: $medium-screen-min-width) {
        max-width: 110px;
      }

      @media only screen and (max-width: $medium-small-screen-min-width) {
        max-width: 28%;
      }

      .avatarContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 auto;

        .avatar {
          display: flex;
          height: 100px;
          width: 100px;
          position: relative;

          @media only screen and (max-width: $medium-screen-min-width) {
            height: 90px;
            width: 90px;
          }

          @media only screen and (max-width: $medium-small-screen-min-width) {
            height: 80px;
            width: 80px;
          }

          svg {
            z-index: 1;
            width: 90%;
            height: 90%;
            margin: auto;
            cursor: pointer;
          }

          &.selected {
            &::before {
              content: ' ';
              border: 5px solid #4abd03;
              width: 87px;
              height: 87px;
              border-radius: 100%;
              position: absolute;
              left: 6%;
              top: 13%;

              @media only screen and (max-width: $medium-screen-min-width) {
                width: 77.5px;
                height: 77.5px;
                border: 4px solid #4abd03;
                left: 7.5%;
                top: 14%;
              }

              @media only screen and (max-width: $medium-small-screen-min-width) {
                width: 70px;
                height: 70px;
                left: 6%;
                top: 12%;
              }
            }

            &::after {
              content: url(./flat_tick_icon.svg);
              position: absolute;
              width: 15%;
              height: 15%;
              right: 3%;
              top: 17%;

              @media only screen and (max-width: $medium-screen-min-width) {
                width: 20%;
                height: 20%;
                right: 3%;
                top: 8%;
              }
            }
          }
        }

        .moreLikeThis {
          display: flex;
          flex-direction: row;
          justify-content: center;
          margin: 8px auto 0;
          color: #3f52ff;
          cursor: pointer;
          align-items: center;
          user-select: none;

          .redoIcon {
            line-height: 20px;
            font-size: 12px;
          }

          .redoLabel {
            line-height: 20px;
            padding-left: 2px;
            padding-top: 2px;
            font-size: 14px;
            margin-left: 4px;
            font-weight: 600;
            font-style: italic;
            text-align: center;

            @media only screen and (max-width: $medium-screen-min-width) {
              font-size: 10px;
              margin-left: 2px;
              text-decoration: underline;
            }
          }
        }
      }
    }
  }

  .avatarGenerateMore {
    display: flex;
    justify-content: center;
  }
}

:global(.caw-theme) {
  .moreAvatarButton {
    border-color: #ce4851;
    color: #ce4851;
  }
}

:global(.ease-theme) {
  .moreAvatarButton {
    color: $ease-dark-grey;
    text-transform: uppercase;
    border-color: $ease-green;
    border-radius: 4px;
  }
}

:global(.recharge-theme) {
  .moreAvatarButton {
    border-color: $recharge-purple;
    color: $recharge-purple;
  }
}

:global(.select-theme) {
  .moreAvatarButton {
    border-color: $select-red;
    color: $select-red;
  }
}
