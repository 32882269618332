@import 'assets/theme';

.topContainer {
  margin: 0 auto;
  display: flex;
  max-width: $a4-print-width;
  align-items: center;
  justify-content: space-between;
  margin-bottom: $smp-standard-padding * 4;

  .status {
    color: white;
    font-size: 1rem;
    margin-bottom: $smp-standard-padding * 2;
  }

  .downloadButton {
    color: white;
    border-color: white;

    &:active {
      background-color: transparent !important;
    }
  }
}
