@import 'HealthBright/assets/theme';

.container {
  display: flex;
  border: 1px dashed $health-bright-dark-blue;
  border-radius: 8px;
  min-width: 106px;
  height: 111px;
  background-color: $health-bright-light-green;
}

.bookContainer {
  display: flex;
  flex-direction: column;
  background: $health-bright-tidal-green;
  border-radius: 8px;
  padding: 4px 8px 8px;
  min-height: 100px;
  min-width: 106px;

  .closeBtn {
    position: relative;
    left: 90px;
    top: -14px;
    height: 0;

    .closeIcon {
      background: $white;
      border-radius: 50%;
      font-size: 18px;
      padding: 2px;
      color: $health-bright-blue800;
      cursor: pointer;
      user-select: none;
    }
  }

  .timeSlot {
    font-size: 10px;
    line-height: 13px;
    background: $white;
    color: $health-bright-blue800;
    border-radius: 24px;
    margin-top: 8px;
    padding: 4px 12px;
    text-align: center;
  }
}

.blockContainer {
  @extend .bookContainer;
  background-color: $health-bright-light-blue;
  border: 2px solid $health-bright-blue800;
}
