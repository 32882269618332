@import 'assets/theme';

.container {
  display: flex;
  position: relative;
  font-family: $smp-font-family;
  flex-direction: column;

  .header {
    display: flex;
    margin-bottom: $smp-standard-padding * 12;
    flex-direction: column;

    .title {
      @include titleFontSize;
    }

    .desc {
      @include subtitle;
    }

    .trophy {
      top: -35px;
      right: -32px;
      height: 180px;
      position: absolute;

      @media screen and (max-width: $smp-mobile-screen-max-content) {
        display: none;
      }
    }
  }

  .content {
    gap: 16px;
    z-index: 1;
    display: flex;

    @media screen and (max-width: $smp-mobile-screen-max-content) {
      gap: $smp-standard-padding * 24;
      flex-direction: column;

      .shadedItem {
        margin: -32px;
        padding: $smp-standard-padding * 16 32px;
        background-color: $smp-vanilla;
      }
    }
  }
}
