@import 'assets/theme';

.content {
  display: flex;
  flex-direction: column;
  padding: 16px 16px 120px;

  .loading {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
  }
}
