@import 'helm/assets/theme';
@import 'SomeoneHealth/assets/theme';
@import 'CaW/assets/theme';
@import 'Recharge/assets/theme';

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
}

.content {
  font-weight: 600;
  font-size: 16px;
}

.note {
  background: $blue50;
  padding: $standard-padding * 5;
  display: flex;
  font-weight: 600;
  font-size: 18px;

  i {
    margin-right: $standard-padding * 3;
  }
}

.disabled {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.5;

  .error {
    display: none;
  }
}

.radio1 {
  margin-bottom: $standard-padding * 6;
}

.inputs {
  width: 100%;
  max-width: 376px;
  font-weight: 400;

  .row {
    height: 71px;
    display: flex;
    align-items: flex-end;

    margin-left: $standard-padding * 4;
    margin-right: -$standard-padding * 4;

    .input {
      margin-left: -$standard-padding * 4;
      padding: $standard-padding * 4;
    }

    .datePicker {
      width: 100%;
    }
  }

  .error {
    margin-left: $standard-padding * 4;
  }
}

.radioLabel {
  width: 180px;
  justify-content: center !important;
}

.uploadedFiles {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: $standard-padding * 2 $standard-padding * 4;
}

.uploadedFile {
  width: 280px;
  display: flex;
  justify-content: space-between;
}

.removeFile {
  padding: 0;
  border: none;
  color: #fc8468;
  display: flex;
  align-items: center;
  background-color: transparent;
}

.uploadReferralContainer {
  margin-bottom: $standard-padding;

  .uploadContainerInput {
    display: none;
  }

  .uploadContainerBtn {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: fit-content;
    padding: $standard-padding * 3 $standard-padding * 2;
    margin-top: $standard-padding * 6;
    border-radius: 4px;
    cursor: pointer;
    background-color: transparent;
    border: 1px solid #3f52ff;
    color: #3f52ff;
    font-weight: 400;

    &.error {
      border: 1px solid #fc8468;
      background: #fc8468;
      color: #fff;
    }

    .publishIcon {
      padding-right: 4px;
    }

    .publishLabel {
      font-size: 14px;
    }
  }

  .shUploadContainerBtn {
    @extend .uploadContainerBtn;
    border: 1px solid $someone-health-pink !important;
    color: $someone-health-pink !important;
  }

  .cawUploadContainerBtn {
    @extend .uploadContainerBtn;
    border: 1px solid $caw-blue !important;
    color: $caw-blue !important;
  }
}

.submitButtonContainer {
  margin-top: $standard-padding * 8;

  .submitBtn {
    width: 100%;
    max-width: 352px;
    box-shadow: none;
  }
}

:global(.recharge-theme) {
  .uploadReferralContainer .uploadContainerBtn {
    color: $recharge-purple;
    border-color: $recharge-purple;
  }
}
