@import 'SomeoneHealth/assets/theme';

.container {
  display: flex;
  flex-direction: column;
  max-width: max-content;

  .selectButton {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 20px;
    border: 0.5px solid $someone-health-blue;
    box-sizing: border-box;
    letter-spacing: 0.1px;
    border-radius: 40px;
    cursor: pointer;
    color: $someone-health-blue;
    background-color: $white;
    font-size: 16px;
    line-height: 21px;
    user-select: none;
    font-weight: 500;

    @media (max-width: $tablet-screen-max-content) {
      font-size: 16px;
      line-height: 21px;
      padding: 8px 16px;
    }

    @media (max-width: $mobile-screen-max-content) {
      font-size: 14px;
    }

    .icon {
      font-size: 30px;
      width: 20px;

      @media (max-width: $tablet-screen-max-content) {
        font-size: 22px;
      }
    }
  }

  .menuWrapper {
    display: flex;
    justify-content: flex-end;
    position: relative;
    width: 100%;
    z-index: 1;

    .menuBoxHide {
      position: absolute;
      width: 100%;
      background: #fff;
      border-radius: 12px;
      border: 2px solid $someone-health-blue;
      visibility: hidden;
      opacity: 0;
      transition: all 0.3s;
      transform: scaleY(0);
      transform-origin: top;

      .listBox {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 12px 12px;
        color: $someone-health-blue;
        transition: all 0.2s ease;
        cursor: pointer;
        position: relative;

        &:not(:last-child) {
          border-bottom: 1px dashed $someone-health-blue;
        }

        &:hover {
          color: #3f52ff;
        }

        .title {
          font-weight: 500;
          font-size: 16px;
          line-height: 21px;

          @media (max-width: $tablet-screen-max-content) {
            font-size: 14px;
          }
        }

        .desc {
          margin-top: 4px;
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
        }
      }
    }

    .menuBoxShow {
      @extend .menuBoxHide;
      visibility: visible;
      opacity: 1;
      transform: scaleY(1);
    }
  }
}
