@import 'assets/theme';
@import 'helm/assets/theme';
@import 'Ease/assets/theme';
@import 'Recharge/assets/theme';
@import 'Select/assets/theme';

$mobile-footer-height: 174px;

.container {
  @media (max-width: $small-screen-max-width) {
    width: 100vw !important;
    max-width: 100vw;
    height: calc(100vh - 174px);
    top: 0;
    margin: 0;
    padding: 0;
  }

  :global {
    .ant-modal-content {
      width: 800px;
      border-top: 10px solid #9342f7;
      border-radius: 10px 10px 8px 8px;

      @media (max-width: $small-screen-max-width) {
        width: 100%;
        height: 100%;
        border-color: #fc8468;
        border-radius: 10px 10px 0 0;
        background-color: white;
      }

      .ant-modal-close {
        svg {
          width: 14px;
          height: 14px;
          fill: #343434;
        }
      }

      .ant-modal-header {
        padding: $standard-padding * 2 $standard-padding * 2.5;

        .ant-modal-title {
          padding: $standard-padding 0;
          font-weight: 600;
          font-size: 24px;
          color: #343434;
        }
      }

      .ant-modal-body {
        padding: 0 !important;
      }
    }
  }
}

.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 44px 6px 4px;

  .downloadInvoiceBtn {
    color: $helm-primary;
    font-size: 14px;
    margin: 0 $standard-padding * 6;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:hover {
      opacity: 0.5;
    }

    .downloadIcon {
      font-size: 20px;
      padding-right: 4px;
    }

    .loading {
      padding-right: 6px;

      &:after {
        border: 3px solid $helm-primary;
        border-color: $helm-primary transparent $helm-primary transparent;
      }
    }
  }

  .downloadingInvoice {
    @extend .downloadInvoiceBtn;
    opacity: 0.6;
    cursor: not-allowed;
    user-select: none;
  }
}

.body {
  min-height: 150px;
  padding-bottom: $standard-padding * 8;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

:global(.ease-theme) {
  :global {
    .ant-modal-content {
      border-top-color: #564197;
    }
  }

  .title .downloadInvoiceBtn {
    color: $blue500;
    text-transform: uppercase;

    .loading {
      &:after {
        border-color: $ease-green transparent $ease-green transparent;
      }
    }
  }
}

:global(.recharge-theme) {
  .container :global {
    .ant-modal-content {
      border-top-color: $recharge-yellow;
    }
  }

  .title .downloadInvoiceBtn {
    color: $recharge-purple;
    text-transform: uppercase;

    .loading {
      &:after {
        border-color: $recharge-purple transparent $recharge-purple transparent;
      }
    }
  }
}

:global(.select-theme) {
  .container :global {
    .ant-modal-content {
      border-top-color: #fcbf49;
    }
  }

  .title .downloadInvoiceBtn {
    color: $select-red;

    .loading {
      &:after {
        border-color: $select-red transparent $select-red transparent;
      }
    }
  }
}
