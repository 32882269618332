@import 'HealthBright/assets/theme';

.loadingContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  top: 0;
  background-color: #ffffffc0;
  position: fixed;
  z-index: 2;
}

.container {
  padding: 58px 0 84px 0;
  min-height: calc(100vh - 118px);

  @media screen and (max-width: $medium-large_screen-min-width) {
    padding: 24px 0 60px 0;
  }
}

.content {
  display: flex;
  justify-content: space-around;

  @media screen and (max-width: $medium-large_screen-min-width) {
    align-items: center;
    flex-direction: column-reverse;
    gap: 10px;
  }

  .subHeader {
    font-weight: 300;
    font-size: 16px;
    line-height: 19.2px;
    color: $colorGrey800;

    .desc {
      margin-top: 20px;
      font-weight: 400;
      font-size: 16px;
      line-height: 21px;
    }
  }
}

.leftContent {
  flex: 2 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 580px;

  .greet {
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    font-family: $quincyCF;
    display: flex;
  }

  .summaryContainer {
    padding: unset;
    align-items: flex-start;

    .summaryContent {
      margin-left: -30px;

      @media screen and (max-width: $medium-large_screen-min-width) {
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  .title {
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: $health-bright-blue800;
  }

  .content {
    font-weight: 300;
    font-size: 14px;
    line-height: 18px;
  }

  .importantInfo,
  .cancellationPolicy {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .checkBox {
    font-size: 16px;
  }

  .submitButton {
    width: 100%;
  }
}

.rightContent {
  @media screen and (min-width: $medium-large_screen-min-width) {
    padding-top: 140px;
  }

  .notice {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 25px;
    flex-direction: column;

    @media screen and (max-width: $medium-large_screen-min-width) {
      flex-direction: row-reverse;
    }
  }

  @media screen and (max-width: $medium-large_screen-min-width) {
    .timeLabel {
      display: flex;
      flex-direction: row;
      justify-content: center;
      font-weight: 700;
      font-size: 20px;
      line-height: 26px;

      > div {
        display: flex;
        flex-direction: column;
        align-items: center;
        div {
          font-weight: 500;
          font-size: 10px;
          line-height: 13px;
        }
      }
    }
  }

  .noticeMsg {
    max-width: 260px;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;

    div {
      text-align: center;
    }

    @media screen and (max-width: $medium-large_screen-min-width) {
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      max-width: unset;
    }
  }
}
