@import 'SomeoneHealth/assets/theme';

.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-size: cover;
  min-height: 440px;
  max-height: 440px;
  background-image: url('~SomeoneHealth/assets/images/background/practitioner-profile-header.png');
  background-position: top;
  background-repeat: no-repeat;
  position: relative;
  z-index: 1;
  flex: 1 0 auto;

  .headerContent {
    display: flex;
    width: 100%;
    flex: initial;
  }

  .headerWrapper {
    display: flex;
  }
}
