@import 'HealthBright/assets/theme';

.container {
  display: flex;
  align-items: center;
  @include columnGap($standard-padding * 3);
  color: $health-bright-blue800;

  .calendarBadge {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: url(~HealthBright/assets/images/calendar/calendarShape.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 55px;
    padding: 15px 4px 4px 3px;

    .calendarContent {
      background-color: $white;
      width: 100%;
      border-radius: 1px;
    }

    .date {
      font-size: 24px;
      line-height: 24px;
      letter-spacing: -0.02em;
      text-align: center;
    }

    .month {
      font-size: 14px;
      font-weight: 500;
      line-height: 14px;
      text-align: center;
    }
  }

  .dayWrapper {
    display: flex;
    flex-direction: column;

    .day {
      font-size: 24px;
      line-height: 24px;
      letter-spacing: -0.02em;
    }

    .remainingDay {
      font-weight: 300;
      font-size: 14px;
      line-height: 14px;
      padding-top: $standard-padding;
    }
  }
}

.containerCenter {
  @extend .container;
  justify-content: center;
}
