@import 'assets/theme';

$mobile-footer-height: 174px;

.container {
  :global {
    .ant-modal-content {
      max-width: 600px;
    }

    .ant-modal-header {
      margin-bottom: 0;

      .ant-modal-title {
        font-weight: 800;
        font-size: 18px;
        color: #343434;
      }
    }
  }

  .content {
    padding-bottom: $standard-padding * 2;
  }

  .actionWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: $standard-padding * 2;
  }
}
