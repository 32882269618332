@import 'assets/theme/index';

.container {
  max-width: 650px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .title {
    width: 200px;
    font-size: 16px;
    font-weight: bold;
    line-height: 22px;
    color: #5666a6;
    margin-bottom: 15px;
  }

  .paymentMethodsContainer {
    max-width: 450px;
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;

    .paymentMethodContainer {
      width: 50%;
      padding-left: 12px;
      padding-bottom: 12px;
      @media (max-width: $medium-small-screen-min-width) {
        max-width: 100%;
      }

      .label {
        font-size: 14px;
        font-weight: bold;
        line-height: 24px;
        letter-spacing: 0.1px;
        color: #343434;
      }

      .description {
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.1px;
        color: #343434;

        .value {
          font-weight: 600;
          color: #3f52ff;
        }
      }
    }
  }
}
