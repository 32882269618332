@import 'HealthBright/assets/theme';

.container {
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  padding: 42px 0;

  .filterWrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: $health-bright-blue800;

    @media (max-width: $medium-desktop-screen-max-content) {
      flex-direction: column;
      align-items: flex-start;
    }

    .header {
      display: flex;
      justify-content: space-between;

      @media (max-width: $medium-desktop-screen-max-content) {
        padding-bottom: 32px;
        align-items: center;
        width: 100%;
      }
    }

    .label {
      font-weight: 400;
      font-size: 16px;
      line-height: 21px;
      margin-right: 16px;
    }

    .filter {
      width: auto;
      flex: 1;
      max-width: 70%;

      @media (max-width: $medium-desktop-screen-max-content) {
        max-width: 100%;
        width: 100%;
      }
    }

    .reset {
      color: $health-bright-blue800;
      font-size: 14px;
      line-height: 18px;
      text-decoration-line: underline;
      margin-left: 24px;
      cursor: pointer;

      &.disabled {
        cursor: not-allowed;
      }
    }

    .headerReset {
      @extend .reset;
      display: none;

      @media (max-width: $medium-desktop-screen-max-content) {
        display: flex;
        padding-right: 12px;
      }
    }

    .filterReset {
      @extend .reset;
      display: flex;

      @media (max-width: $medium-desktop-screen-max-content) {
        display: none;
      }
    }
  }
}
