@import 'SomeoneHealth/assets/theme';

.actionWrapper {
  display: flex;
  flex-direction: column;
  @include rowGap(16px);
}

.title {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  color: $someone-health-blue;
}

.menuItemWrapper {
  display: flex;
  flex-direction: column;
  gap: $standard-padding * 4;
}

.taskButtonText {
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;

  .highlight {
    color: $someone-health-blue;
    font-weight: 700;
  }
}
