@import 'SomeoneHealth/assets/theme';

.wrapper {
  display: flex;
  flex-direction: column;
  @include rowGap(24px);

  .title {
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    color: $someone-health-blue;
  }

  .contact {
    font-size: 18px;
    font-weight: 400;
    line-height: 23px;
    color: $colorGrey700;

    .highlight {
      @extend .contact;
      color: $someone-health-blue;
      font-weight: 700;
    }
  }

  .contactBtn {
    border: 1px solid $someone-health-blue !important;
    color: $someone-health-blue !important;
    &:hover {
      background-color: $darkblue50 !important;
    }
  }
}
