@import 'assets/theme';

.container {
  margin: 0 auto;
  max-width: $a4-print-width;

  .formContainer {
    gap: 16px;
    padding: $smp-standard-padding * 10 32px 0;
    border-radius: 12px;
    background-color: $white;

    .pageWrapper {
      height: 297mm;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .contentWrapper {
        display: flex;
        flex-direction: column;
      }
    }

    .actionContainer {
      gap: $smp-standard-padding * 8;
      display: flex;
      padding: $smp-standard-padding * 8 0;
      justify-content: center;

      @media print {
        display: none;
      }
    }
  }
}

.updatingModal {
  font-family: $smp-font-family;

  .updatingModalBodyContainer {
    display: flex;
    align-items: center;
  }

  .warningIcon {
    color: $smp-yellow;
    display: inline-block;
  }

  .updatingModelBody {
    display: inline-block;
    margin-right: $smp-standard-padding;
  }
}

.previewContainer {
  position: absolute;
  top: -99999px;
}
