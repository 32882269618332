@import 'assets/theme';
@import 'CaW/assets/theme';
@import 'Ease/assets/theme';
@import 'Recharge/assets/theme';
@import 'Select/assets/theme';

.container {
  display: flex;
  flex-direction: column;
  color: #fff;
  position: relative;
  z-index: 3;

  .contentWrapper {
    display: flex;
    flex-direction: column;
  }

  .header {
    padding: 16px 16px 0;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .profilePic {
      width: 100px;
      height: 100px;
    }

    .logout {
      display: flex;
      align-items: center;
      cursor: pointer;
      margin-top: $standard-padding * 2;
      margin-right: $standard-padding;

      span {
        margin-left: 4px;
      }
    }
  }
  .content {
    font-size: 18px;
    padding: 20px;
    margin-bottom: 180px;

    .title {
      font-size: 28px;
      font-weight: 600;
    }

    .text {
      color: #fff;
      padding: 16px 0;
    }

    p {
      margin-top: $standard-padding * 2;
    }

    .signature {
      font-weight: 600;
    }
  }

  .card {
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    background-color: $white;
    background-image: url(~assets/images/background-circles.png);
    background-size: cover;
    background-repeat: no-repeat;
    height: 180px;
    position: fixed;
    bottom: 0;
    width: 100%;
    display: flex;

    .doneContent {
      display: flex;
      flex-direction: column;
      font-size: 15px;
      width: 100%;
      justify-content: center;
      align-items: center;

      .successWrapper {
        display: flex;
        align-items: center;

        .checkIcon {
          padding: 12px;
          font-size: 30px;
          border: 2px solid #4abd03;
          border-radius: 50%;
          color: #4abd03;
          box-sizing: border-box;
        }

        .doneText {
          color: #3f52ff;
          margin-left: $standard-padding * 2;
        }
      }

      .homePageBtn {
        display: flex;
        color: #343434;
        margin-top: 16px;
        justify-content: center;
        cursor: pointer;
      }
    }

    .returnContent {
      width: 100%;

      .progress {
        display: flex;
        width: 110px;
        margin-top: $standard-padding * 4;
        margin-left: $standard-padding * 3;
        flex-direction: column;
        justify-content: center;
        font-weight: bold;
        font-size: 11px;
        color: $cta;
      }

      .button {
        width: 75%;
        margin: auto;
        margin-top: $standard-padding * 5;
      }
    }
  }
}

:global(.someone-health-theme) {
  .card {
    box-shadow: -2px -2px 16px rgba(0, 0, 0, 0.1);
    border-radius: 30px;
  }
}

:global(.caw-theme).container {
  color: $caw-blue;

  .card {
    box-shadow: -2px -2px 16px rgba(0, 0, 0, 0.1);
    border-radius: 30px;
  }

  .text {
    color: $caw-blue;
  }
}

:global(.ease-theme).container {
  color: $ease-dark-purple;

  .card {
    background: $white;
    box-shadow: -2px -2px 16px rgba(0, 0, 0, 0.1);
    border-radius: 30px;

    .doneContent .successWrapper .doneText,
    .returnContent .progress {
      color: $ease-dark-grey;
    }
  }

  .title {
    color: $ease-dark-purple;
  }

  .text {
    color: $ease-dark-grey;
  }
}

:global(.recharge-theme).container {
  .card {
    background: $white;
    box-shadow: -2px -2px 16px rgba(0, 0, 0, 0.1);
    border-radius: 30px;
  }

  .doneContent .successWrapper .doneText,
  .returnContent .progress {
    color: $recharge-black;
  }
}

:global(.select-theme).container {
  color: $select-black;

  .card {
    background: $white;
    box-shadow: -2px -2px 16px rgba(0, 0, 0, 0.1);
    border-radius: 30px;

    .doneContent .successWrapper .doneText,
    .returnContent .progress {
      color: $select-black;
    }
  }

  .title {
    color: $select-black;
  }

  .text {
    color: $select-black;
  }
}
