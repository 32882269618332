@import 'SomeoneHealth/assets/theme';
@import 'Ease/assets/theme';
@import 'Recharge/assets/theme';
@import 'Select/assets/theme';
@import 'HealthBright/assets/theme';

.select {
  width: 100%;
  border-radius: 8px;

  &.error {
    border: 1px solid #fc8468;
    margin-bottom: 0;
  }

  &.noSpacing {
    margin-bottom: 0;
  }
}

.label {
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  color: #818e9b;
  margin-bottom: 4px;
}

:global(.someone-health-theme) {
  .label {
    color: $grey600;
    font-size: 12px;
    font-weight: 400;
  }
  .focusLabelClass {
    color: $someone-health-pink !important;
  }
}

:global(.ease-theme) {
  .label {
    color: $grey600;
    font-size: 12px;
    font-weight: 400;
  }
  .focusLabelClass {
    color: $ease-dark-grey !important;
  }
}

:global(.recharge-theme) {
  .label {
    color: $grey600;
    font-size: 12px;
    font-weight: 400;
  }
  .focusLabelClass {
    color: $recharge-black !important;
  }
}

:global(.select-theme) {
  .label {
    color: $grey600;
    font-size: 12px;
    font-weight: 400;
  }
  .focusLabelClass {
    color: $select-black !important;
  }
}

:global(.health-bright-theme) {
  .label {
    color: $grey600;
    font-size: 12px;
    font-weight: 400;
  }
  .focusLabelClass {
    color: $health-bright-blue800;
  }
}
