@import 'SomeoneHealth/assets/theme';
@import 'CaW/assets/theme';
@import 'Ease/assets/theme';
@import 'Recharge/assets/theme';
@import 'Select/assets/theme';

.container {
  position: relative;
}

.label {
  position: absolute;
  pointer-events: none;
  font-size: 18px;
  top: 30px;
  color: #818e9b;
  font-weight: 600;
  transition: 0.2s ease all;
}

.errorLabel {
  color: #ff1900 !important;
}

:global(.someone-health-theme) {
  .label {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: $colorGrey600;
  }
}

:global(.caw-theme) {
  .label {
    font-weight: bold;
    font-size: 11px;
    line-height: 16px;
    color: $colorGrey600;
  }
}

:global(.helm-theme) {
  .label {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: $colorGrey600;
  }
}

:global(.ease-theme) {
  .label {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: $colorGrey600;
  }
}

:global(.recharge-theme) {
  .label {
    font-size: 12px;
    line-height: 16px;
    color: $colorGrey600;
  }
}

:global(.select-theme) {
  .label {
    font-size: 12px;
    line-height: 16px;
    color: $colorGrey600;
  }
}
