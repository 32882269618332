@import 'SomeoneHealth/assets/theme';

.container {
  display: flex;
  border: 1px dashed #f15944;
  border-radius: 8px;
  min-width: 106px;
  height: 111px;
  background-color: $someone-health-light-pink;
}

.bookContainer {
  display: flex;
  flex-direction: column;
  background: linear-gradient(91.74deg, #DB3C98 30.48%, #30208B 99.77%);;
  border-radius: 8px;
  padding: 4px 8px 8px;
  min-height: 100px;
  min-width: 106px;

  .closeBtn {
    position: relative;
    left: 90px;
    top: -14px;
    height: 0;

    .closeIcon {
      background: $white;
      border-radius: 50%;
      font-size: 18px;
      padding: 2px;
      color: $someone-health-red;
      cursor: pointer;
      user-select: none;
    }
  }

  .timeSlot {
    font-size: 10px;
    line-height: 13px;
    background: $white;
    color:  $someone-health-blue;
    border-radius: 24px;
    margin-top: 8px;
    padding: 4px 12px;
    text-align: center;
  }
}

.blockContainer {
  @extend .bookContainer;
  background-color: $someone-health-light-pink2;
  border: 2px solid $someone-health-blue;
}
