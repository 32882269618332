@import 'SomeoneHealth/assets/theme';

.loading {
  height: 500px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  .content {
    max-width: 1280px;
    background-color: white;
    border-radius: 8px;
    padding: $standard-padding * 6 $standard-padding * 8;
    margin: $standard-padding * 6 $standard-padding * 4;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

    @media (max-width: $tablet-screen-max-content) {
      padding: $standard-padding * 4;
      margin: $standard-padding * 3 $standard-padding * 2;
    }

    .header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: $standard-padding * 4;
      @media (max-width: $tablet-screen-max-content) {
        @include rowGap($standard-padding * 2);
        flex-direction: column;
      }
      .title {
        font-size: 28px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: $darkblue500;
      }
    }

    .psychologistInfo {
      display: flex;
      flex-direction: row;
      margin-bottom: $standard-padding * 6;
      z-index: 10;

      @media (max-width: $tablet-screen-max-content) {
        flex-direction: column;
        margin-bottom: $standard-padding;
      }

      .avatar {
        min-width: 20%;
        padding: $standard-padding * 6;
        justify-items: center;
        align-self: center;
        @media (max-width: $tablet-screen-max-content) {
          padding: $standard-padding;
        }
      }

      .overview {
        display: flex;
        flex-direction: column;
        padding: $standard-padding * 4;

        @media (max-width: $tablet-screen-max-content) {
          flex-direction: row;
          max-width: 100%;
          padding: $standard-padding * 4 0;
        }

        @media (max-width: $mobile-screen-max-content) {
          flex-direction: column;
        }

        .detailsWrapper {
          @include rowGap(32px);

          @media (max-width: $tablet-screen-max-content) {
            @include rowGap(16px);
          }
        }

        .bonaFidesWrapper {
          @include rowGap(32px);

          @media (max-width: $tablet-screen-max-content) {
            @include rowGap(16px);
            margin-left: 60px;
          }

          @media (max-width: $mobile-screen-max-content) {
            margin-left: 0;
          }
        }

        .profileImg {
          width: 250px;
        }

        .scheduleButton {
          width: 100%;
          margin-bottom: 32px;
        }
      }

      .detail {
        display: flex;
        padding: $standard-padding * 6;
        flex-direction: column;
        z-index: 1;
        @include rowGap(16px);

        @media (max-width: $tablet-screen-max-content) {
          padding: $standard-padding * 4 0;
        }

        .greet {
          color: $someone-health-blue;
          font-weight: 800;
          font-size: 40px;
          line-height: 44px;
          padding-bottom: $standard-padding * 5;

          @media (max-width: $tablet-screen-max-content) {
            font-size: 40px;
            line-height: 44px;
            padding-bottom: $standard-padding * 4;
            margin-bottom: 0;
          }
        }

        .descWrapper {
          display: flex;
          flex-direction: column;
          @include rowGap(16px);

          .pronouns {
            font-weight: 500;
            font-size: 18px;
            line-height: 23px;
            color: $someone-health-blue;
            padding-bottom: $standard-padding * 2;
          }

          .desc {
            display: flex;
            flex-direction: column;
            padding-bottom: 16px;

            .label {
              font-weight: 400;
              font-size: 12px;
              line-height: 16px;
              padding-bottom: 16px;
              color: $someone-health-blue;
            }
          }
          .mainAreaBadge {
            background-color: $someone-health-light-pink2;
          }
        }

        .contentDesc {
          white-space: pre-line;
          font-size: 20px;
          line-height: 26px;
          color: $someone-health-blue;
          font-weight: 500;

          @media (max-width: $small-desktop-screen-max-content) {
            font-size: 16px;
            line-height: 21px;
          }
        }

        .headerDesc {
          white-space: pre-line;
          font-size: 28px;
          line-height: 26px;
          color: $someone-health-blue;
          font-weight: 700;
          @media (max-width: $small-desktop-screen-max-content) {
            font-size: 23px;
            line-height: 21px;
          }
        }

        .subDesc {
          white-space: pre-line;
          font-size: 16px;
          line-height: 26px;
          color: $someone-health-blue;
          font-weight: 400;
        }
      }
    }

    .howItWork {
      position: relative;
    }
  }
}
