@import 'Recharge/assets/theme';
@import 'Select/assets/theme';
@import 'HealthBright/assets/theme';

.tooltip {
  max-width: 230px;
  border-radius: 8px;
  line-height: 16px;
  font-size: 14px;
  padding: 12px;
  opacity: 0.95 !important;
}

.tooltipWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  margin-left: 20px;

  p {
    font-size: 12px;
    margin-left: 2px;
    margin-bottom: 0;
    color: #fd8468;
  }
  .toolTipsIcon {
    margin-left: 2px;
    color: #fd8468;
    border-radius: 50%;
    font-size: 20px;
  }
}

:global(.recharge-theme) {
  .tooltipWrapper {
    p {
      color: $recharge-red;
      font-weight: 600;
    }
  }
}

:global(.select-theme) {
  .tooltipWrapper {
    p {
      color: $select-red;
      font-weight: 600;
    }
  }
}

:global(.health-bright-theme) {
  .tooltipWrapper {
    p {
      color: $health-bright-tidal-green;
      font-weight: 600;
    }
  }
}
