@import '../../assets/theme/index';
@import 'SomeoneHealth/assets/theme';
@import 'Ease/assets/theme';
@import 'Recharge/assets/theme';
@import 'Select/assets/theme';
@import 'HealthBright/assets/theme';

.container {
  display: flex;
  flex: 1;
  position: relative;

  .field {
    border: 0;
    border-bottom: 1px solid #c4c4c4;
    color: #192a3e;
    outline: none;
    transition: 0.2s ease-in-out;
    box-sizing: border-box;
    width: 100%;
    height: 30px;
    font-size: 16px;

    &:valid {
      border-bottom: 1px solid #c4c4c4;
      + {
        .label {
          color: #818e9b;
          font-size: 11px;
          font-weight: 600;
          left: 2px;
          bottom: 22px;
          pointer-events: none;
        }
      }
    }
    &:focus {
      border-bottom: 1px solid #c4c4c4;
      + {
        .label {
          color: #ff8c00;
          font-size: 11px;
          font-weight: 600;
          left: 2px;
          bottom: 22px;
          pointer-events: none;
        }
      }
    }

    &.error {
      border-bottom: 1px solid $main-red;
      + {
        .label {
          color: $main-red;
          font-size: 11px;
          font-weight: 600;
          left: 2px;
          bottom: 22px;
          pointer-events: none;
        }
      }
    }
  }

  .fieldWithComponent {
    @extend .field;
    padding-right: 40px;
  }

  .label {
    bottom: 0;
    left: 0;
    color: #818e9b;
    display: flex;
    align-items: center;
    position: absolute;
    font-size: 14px;
    cursor: text;
    transition: 0.2s ease-in-out;
    box-sizing: border-box;
    width: 100%;
    height: 30px;
    text-transform: capitalize;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.someone_health_theme {
  &:valid {
    + {
      .label {
        font-size: 12px !important;
        color: $colorGrey600 !important;
        font-weight: 400 !important;
      }
    }
  }

  &:focus {
    + {
      .label {
        font-size: 12px !important;
        color: $someone-health-pink !important;
        font-weight: 400 !important;
      }
    }
  }
}

:global(.ease-theme) {
  .field {
    &:valid {
      + {
        .label {
          font-size: 12px !important;
          color: $colorGrey600 !important;
          font-weight: 400 !important;
        }
      }
    }

    &:focus {
      + {
        .label {
          font-size: 12px !important;
          color: $ease-dark-grey !important;
          font-weight: 400 !important;
        }
      }
    }
  }
}

:global(.recharge-theme) {
  .field {
    font-size: 13px;
    background-color: transparent;

    + {
      .label {
        font-weight: 600 !important;
        font-size: 12px !important;
        color: $recharge-black !important;
      }
    }

    &:valid {
      + {
        .label {
          font-weight: 600 !important;
          font-size: 12px !important;
          color: $colorGrey600 !important;
        }
      }
    }

    &:focus {
      + {
        .label {
          font-weight: 600 !important;
          font-size: 12px !important;
          color: $recharge-black !important;
        }
      }
    }
  }
}

:global(.select-theme) {
  .field {
    font-size: 13px;
    background-color: transparent;

    + {
      .label {
        font-weight: 600 !important;
        font-size: 12px !important;
        color: $select-black !important;
      }
    }

    &:valid {
      + {
        .label {
          font-weight: 600 !important;
          font-size: 12px !important;
          color: $colorGrey600 !important;
        }
      }
    }

    &:focus {
      + {
        .label {
          font-weight: 600 !important;
          font-size: 12px !important;
          color: $select-black !important;
        }
      }
    }
  }
}

:global(.health-bright-theme) {
  .field {
    height: 38px;
    transform: translateX(-4px);
    background-color: transparent;

    + {
      .label {
        font-weight: 400 !important;
        bottom: 10px;
        color: $colorTextualGray;
        font-size: 16px;
      }
    }

    &:valid {
      + {
        .label {
          bottom: 30px;
          font-size: 12px;
          left: 0;
        }
      }
    }

    &:focus {
      + {
        .label {
          font-size: 12px;
          color: $health-bright-blue800;
          bottom: 30px;
          left: 0;
        }
      }
    }
  }
}
