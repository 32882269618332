@import 'HealthBright/assets/theme';

.container {
  padding: 30px 0;

  .filterHeader {
    display: flex;
    justify-content: space-between;
    cursor: pointer;

    .labelWrapper {
      user-select: none;
    }

    .label {
      font-weight: 500;
      font-size: 16px;
      line-height: 21px;
      color: $health-bright-blue800;
    }

    .expandLabel {
      @extend .label;
      text-decoration: underline;
    }

    .icon {
      user-select: none;
    }

    .selectedValueLabel {
      margin-top: 8px;
      border-radius: 40px;
      color: $health-bright-blue800;
      background-color: $health-bright-green;
      font-weight: 400;
      padding: 12px;
    }
  }

  .filterBody {
    margin-top: 12px;
  }

  .optionItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px;
    color: $health-bright-blue800;
    transition: all 0.2s ease;
    cursor: pointer;
    border-radius: 4px;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;

    &:hover,
    &.selected {
      color: $health-bright-blue800;
      background-color: $health-bright-light-green;
    }
  }
}
