@import 'SomeoneHealth/assets/theme';
@import 'helm/assets/theme';
@import 'Ease/assets/theme';
@import 'Recharge/assets/theme';
@import 'Select/assets/theme';
@import 'HealthBright/assets/theme';

.ant-picker {
  border: none;
  border-bottom: 1px solid #d3d8dd;
  border-radius: 0;
  background-color: transparent;
  box-shadow: none;
  outline: none;
  height: 36px;
  color: #343434;
  font-size: 13px;
  padding: 0;
  line-height: 18px;
  width: 100%;
  margin-top: 24px;

  input::placeholder {
    color: #8f8f94;
  }

  .ant-picker-suffix {
    font-size: 20px;
    margin-right: 4px;
  }

  &.error {
    border-bottom: 1px solid #ff1900;
    margin-bottom: 0;

    ~ div {
      color: #ff1900;
    }
  }

  &.sh-error {
    border-bottom: 1px solid #ff1900;
    margin-bottom: 0;

    ~ div {
      color: #ff1900;
      font-weight: normal;
      font-size: 12px;
    }
  }
}

.ant-picker {
  ~ div {
    top: 8px;
    font-size: 11px;
    opacity: 1;
    font-weight: 600;
  }
}

.ant-picker:hover,
.ant-picker-focused {
  border-color: #d3d8dd;
  border-right-width: 1px !important;
}

.ant-picker-focused {
  ~ div {
    color: #3f52ff;
  }
}

.someone-health-theme.ant-picker-focused {
  ~ div {
    color: $someone-health-pink !important;
  }
}

.someone-health-theme.ant-picker.error ~ div {
  font-size: 12px;
  font-weight: 400;
  color: #ff1900 !important;
}

//.helm-theme.ant-picker-focused {
//  ~ div {
//    color: $helm-blue;
//  }
//}

.ease-theme.ant-picker-focused {
  ~ div {
    color: $ease-dark-grey;
  }
}

.recharge-theme .ant-picker-focused {
  ~ div {
    color: $recharge-black;
  }
}

.select-theme .ant-picker-focused {
  ~ div {
    color: $select-black;
  }
}

.health-bright-theme {
  .ant-picker ~ div {
    font-size: 12px;
    font-weight: 400;
  }
  .ant-picker-input > input {
    color: $health-bright-blue800;
    font-weight: 400;
    font-size: 16px;
  }

  .ant-picker-focused {
    ~ div {
      color: $health-bright-blue800;
    }
  }
}
