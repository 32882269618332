@import 'assets/theme';
@import 'CaW/assets/theme';
@import 'Recharge/assets/theme';

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: #2a4670;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
}

.shLoading {
  @extend .loading;
  background-size: cover;
  min-height: 440px;
  max-height: 440px;
  background-image: url('~SomeoneHealth/assets/images/background/practitioner-profile-header.png');
  background-position: top;
  background-repeat: no-repeat;
}

.cawLoading {
  @extend .loading;
  background-color: $caw-blue;
}

.easeLoading {
  @extend .loading;
  background-color: transparent;
}

.rechargeLoading {
  @extend .loading;
  background-color: transparent;
}

.selectLoading {
  @extend .loading;
  background-color: transparent;
}

.container {
  height: 100vh;
  background-color: #2a4670;

  .bgContainer {
    display: flex;
    flex-direction: column;
    background: #fff;
    border-radius: 6px;
    margin: 16px 16px 24px;

    .bgContent {
      max-width: 550px;
    }
  }
}

.shContainer {
  @extend .container;
  height: 100vh;
  background-size: cover;
  min-height: 440px;
  max-height: 440px;
  background-image: url('~SomeoneHealth/assets/images/background/practitioner-profile-header.png');
  background-position: top;
  background-repeat: no-repeat;
  position: relative;
  z-index: 1;

  .bgContainer {
    box-shadow: 0 2px 16px rgba(0, 0, 0, 0.1);
  }
}

.cawContainer {
  @extend .container;
  @include loginBackground;
  background-color: transparent;

  .bgContainer {
    box-shadow: 0 2px 16px rgba(0, 0, 0, 0.1);
  }
}

.easeContainer {
  @extend .container;
  background-color: transparent;

  .bgContainer {
    box-shadow: 0 2px 16px rgba(0, 0, 0, 0.1);
  }
}

.rechargeContainer {
  @extend .container;
  background-color: transparent;

  .bgContainer {
    box-shadow: 0 2px 16px rgba(0, 0, 0, 0.1);
  }
}

.selectContainer {
  @extend .container;
  background-color: transparent;

  .bgContainer {
    box-shadow: 0 2px 16px rgba(0, 0, 0, 0.1);
  }
}

.gatewayContainer {
  height: 100vh;
  background-color: #2a4670;
  position: relative;
  z-index: 1;
}

.gatewayContainer::after {
  position: absolute;
  content: ' ';
  display: block;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 2;
  height: 100vh;
  opacity: 0.8;
  background-color: #2a4670;
}

.shGatewayContainer {
  height: 100vh;
  background-size: cover;
  min-height: 440px;
  max-height: 440px;
  background-image: url('~SomeoneHealth/assets/images/background/practitioner-profile-header.png');
  background-position: top;
  background-repeat: no-repeat;
  position: relative;
  z-index: 1;
}

.shGatewayContainer::after {
  position: absolute;
  content: ' ';
  display: block;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 2;
  height: 100vh;
  opacity: 0.8;
  background-size: cover;
  min-height: 440px;
  max-height: 440px;
  background-image: url('~SomeoneHealth/assets/images/background/practitioner-profile-header.png');
  background-position: top;
  background-repeat: no-repeat;
}

.cawGatewayContainer {
  @include loginBackground;

  &::after {
    background-color: transparent;
  }
}

.easeGatewayContainer {
  &::after {
    background-color: transparent;
  }
}

.rechargeGatewayContainer {
  &::after {
    background-color: transparent;
  }
}

.selectGatewayContainer {
  &::after {
    background-color: transparent;
  }
}

.content {
  position: relative;

  @media (max-width: $medium-screen-min-width) {
    padding: 0;
  }
}
