@import 'HealthBright/assets/theme';

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;

  .header {
    color: $health-bright-blue800;
    font-size: 18px;
    font-weight: 500;
    line-height: 23px;
  }

  .inputLabel {
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
  }

  .customInput {
    transform: translateX(-$standard-padding);
    padding: 0 $standard-padding;
    color: $health-bright-dark-green;
    margin-bottom: $standard-padding !important;
    font-size: 16px;
  }

  .customSelect {
    padding: $standard-padding * 2 0;
  }

  .selectInputLabel {
    @extend .inputLabel;
    margin-bottom: 0;
    font-size: 12px;

    &:focus {
      color: $health-bright-blue800 !important;
    }
  }

  .formWrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .buttonGroup {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap-reverse;
    gap: 8px;
  }
}
