//Health Bright Colors
$health-bright-dark-blue: #0f332f;
$health-bright-blue800: #11211f;
$health-bright-green: #eafaeb;
$health-bright-light-green: #edf2f1;

$health-bright-light-yellow: #fff6e5;
$health-bright-yellow: #ffb120;

$health-bright-light-blue: #fafbfa;
$health-bright-dark-green: #0f332f;
$health-bright-tidal-green: #307467;
$health-bright-black: #414141;
$health-bright-red: #eb001b;
$health-bright-light-grey: #fafbfa;

$white: #ffffff;
$pink: #fd5f87;

$colorRed500: #d54141;

$colorBlue500: #3f52ff;
$colorDarkBlue500: #263054;

$colorGrey50: #f9f9fa;
$colorGrey100: #ecedef;
$colorGrey400: #b5b8bd;
$colorGrey500: #94969d;
$colorGrey600: #61666e;
$colorGrey700: #414449;
$colorGrey800: #202225;

$colorTextualGray: #818e9b;
