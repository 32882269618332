@import 'assets/theme';
@import 'CaW/assets/theme';
@import 'Ease/assets/theme';
@import 'Recharge/assets/theme';
@import 'Select/assets/theme';

.container {
  color: $white;

  .title {
    font-size: 28px;
    font-weight: 600;
  }

  .introNote {
    font-size: 18px;
    padding: 16px 0 0;
    line-height: 30px;
  }

  .footerBtnCard {
    padding: 24px 16px;
    border-radius: 30px 30px 0 0;
    background-color: $white;
    background-image: url(~assets/images/background-circles.png);
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    bottom: 0;
    width: 100%;
    max-width: 1280px;
    margin: 0 -16px;

    .doneContent {
      display: flex;
      flex-direction: column;
      font-size: 15px;
      width: 100%;
      justify-content: center;
      align-items: center;

      .successWrapper {
        display: flex;
        align-items: center;

        .checkIcon {
          padding: 12px;
          font-size: 30px;
          border: 2px solid #4abd03;
          border-radius: 50%;
          color: #4abd03;
          box-sizing: border-box;
        }

        .doneText {
          color: #3f52ff;
          margin-left: $standard-padding * 2;
        }
      }

      .homePageBtn {
        display: flex;
        color: #343434;
        margin-top: 16px;
        justify-content: center;
        cursor: pointer;
      }
    }
  }
}

:global(.caw-theme) {
  .footerBtnCard {
    max-width: 100%;
    left: 1em;
  }

  .title {
    color: $caw-blue !important;
  }

  .introNote {
    color: $caw-blue !important;
  }
}

:global(.ease-theme) {
  .footerBtnCard {
    max-width: 100%;
    background-image: none;

    .doneContent .successWrapper .doneText {
      color: $ease-dark-grey;
    }
  }

  .title {
    color: $ease-dark-purple !important;
  }

  .introNote {
    color: $ease-dark-grey !important;
  }
}

:global(.recharge-theme) {
  .footerBtnCard {
    max-width: 100%;
    background-image: none;

    .doneContent .successWrapper .doneText {
      color: $recharge-black;
    }
  }
}

:global(.select-theme) {
  .footerBtnCard {
    max-width: 100%;
    background-image: none;

    .doneContent .successWrapper .doneText {
      color: $select-black;
    }
  }

  .title {
    color: $select-black !important;
  }

  .introNote {
    color: $select-black !important;
  }
}
