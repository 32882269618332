@import 'Ease/assets/theme/';
@import 'assets/theme/';

.container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  .loadingContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    top: 0;
    position: fixed;
    z-index: 2;
  }

  .header {
    height: 30px;
    width: 100%;
    background-color: #2a4670;
  }

  .footer {
    height: 30px;
    width: 100%;
    background-color: #2a4670;
    align-items: center;
    display: flex;
    padding-left: 8px;

    .logo {
      width: 46px;
    }

    span {
      font-size: 10px;
      color: white;
      margin-left: 8px;

      a {
        color: white;
        text-decoration: underline;
      }
    }
  }
}

:global(.ease-theme).container {
  .header {
    background: transparent;
  }

  .footer {
    background-color: transparent;

    span {
      color: $ease-dark-grey;

      a {
        color: $ease-dark-grey;
      }
    }
  }
}
