@import 'Ease/assets/theme';
@import 'Recharge/assets/theme';
@import 'Select/assets/theme';
@import 'assets/theme';

.container {
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
}

.top {
  display: flex;

  .stepBackBtn {
    display: flex;
    align-items: center;
    font-size: 11px;
    font-style: normal;
    font-weight: bold;
    color: #343434;
    background-color: transparent;
    padding: 0;
    margin-bottom: 16px;

    &:active {
      opacity: 0.8;
    }
  }

  .icon {
    font-size: 18px;
    margin-right: $standard-padding * 0.5;
  }
}

.bottom {
  display: flex;
  justify-content: space-between;

  .progress {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-weight: bold;
    font-size: 11px;
    color: $cta;
  }

  .button {
    display: flex;
    align-items: center;
    padding: 0;
    font-size: 11px;
    font-style: normal;
    font-weight: bold;
    color: #34343480;
    background-color: transparent;

    &:active {
      opacity: 0.8;
    }
  }

  .icon {
    font-size: 18px;
    margin-left: $standard-padding * 0.5;
  }
}

:global(.ease-theme) {
  .top .stepBackBtn {
    color: $blue500;
  }

  .bottom .progress {
    color: $ease-dark-grey;
  }
}

:global(.recharge-theme) {
  .top .stepBackBtn {
    color: $recharge-purple;
  }

  .bottom .progress {
    color: $recharge-purple;
  }
}

:global(.select-theme) {
  .top .stepBackBtn {
    color: $select-black;
  }

  .bottom .progress {
    color: $select-black;
  }
}
