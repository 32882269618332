@import 'HealthBright/assets/theme';

.container {
  margin-top: 48px;
  display: flex;
  width: 100%;
  padding-bottom: 30px;
  justify-content: space-around;

  @media (max-width: $medium-desktop-screen-max-content) {
    padding-left: 100px;
  }

  @media (max-width: $small-desktop-screen-max-content) {
    padding-left: 0;
    padding-right: 0;
    justify-content: space-evenly;
    padding-bottom: unset;
  }

  @media (max-width: $tablet-screen-max-content) {
    margin-top: 24px;
  }
}

.row {
  display: flex;
  gap: 117px;
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  top: 0;
  background-color: #ffffffc0;
  position: fixed;
  z-index: 1000;
}

.loginTextClass {
  color: $health-bright-yellow !important;
}

.leftContent {
  max-width: 577px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.formWrapper {
  display: flex;
  flex-direction: column;
  gap: 28px;
}

.header {
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 28px;
  display: block;
  @include rowGap(8px);

  .welcome {
    font-family: $quincyCF;
  }

  .highlight {
    color: $health-bright-yellow;
  }
}

.subHeader {
  font-weight: 300;
  font-size: 14px;
  line-height: 19px;
  margin-bottom: 6px;
}

.submitButton {
  width: 100%;
  margin-bottom: $standard-padding * 15;

  @media (max-width: $mobile-screen-max-content) {
    margin-bottom: $standard-padding * 20;
  }
}

.rightContent {
  max-width: 410px;

  @media (max-width: $small-desktop-screen-max-content) {
    max-width: 350px;
  }

  .clientTest {
    width: 100%;
  }

  .topNotch {
    display: flex;
    justify-content: center;
    margin-top: 54px;
    width: 100%;
    height: 435px;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border: 2px solid $health-bright-yellow;
    flex-direction: column;
    padding: 0px 72px 20px;
    gap: 20px;

    .topNotchTitle {
      font-weight: 500;
      font-size: 20px;
      line-height: 26px;
      text-align: center;
      color: $health-bright-yellow;
    }

    .topNotchDesc {
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      text-align: center;
      color: $health-bright-blue800;
    }
  }

  @media (max-width: $tablet-screen-max-content) {
    display: none;
  }
}
