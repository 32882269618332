@import 'HealthBright/assets/theme';

.headerContainer {
  display: flex;
  flex-direction: column;
  background-image: url(~HealthBright/assets/images/background/practitioner-list-header.png);
  background-size: cover;
  background-position: center;

  .header {
    display: flex;
    flex-direction: row;

    .headerText {
      width: 150%;
      height: fit-content;
      margin-top: 30px;
      font-size: 36px;
      font-family: $quincyCF;
      line-height: 42px;
      color: $health-bright-blue800;
      font-weight: 600;
      align-content: center;
    }

    @media (max-width: $tablet-screen-max-content) {
      flex-direction: column;
      .headerText {
        display: none;
      }
    }
  }
}
