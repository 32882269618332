// someone.health main color
$someone-health-light-pink: #fef4f7;
//$someone-health-pink: rgba(218, 60, 144, 0.22);
$someone-health-light-blue: #f6fafc;
$someone-health-blue: #06206d;
$someone-health-blue500: #5167f6;
$someone-health-grey500: #94969d;
$someone-health-grey700: #414449;
$someone-health-grey800: #1b114a;
$someone-health-pink: #fd5f87;
$someone-health-pink_2: #fd6985;
$someone-health-button_color: linear-gradient(86.21deg, #f9897d -13.88%, #fd5f87 118.3%);
$someone-health-light-pink2: #f8d8e9;
$someone-health-black: #414141;
$someone-health-light-green: #cfebdb;
$someone-health-red: #eb001b;
$someone-health-light-red: #d54141;
$someone-health-green: #63c364;
$someone-health-yellow: #f3da00;
$someone-health-purple: #564197;

$colorGrey50: #f9f9fa;
$colorGrey100: #ecedef;
$colorGrey500: #94969d;
$colorGrey600: #61666e;
$colorGrey700: #414449;
$colorGrey800: #202225;

$text-disable-grey: #aaaaaa;

$badge-background-color: rgba(44, 52, 96, 0.2);

$black: #000000;
$white: #ffffff;

$darkblue50: #eaedfa;
$darkblue200: #6e80dc;
$darkblue300: #314bcb;
$darkblue400: #22348c;
$darkblue500: #2a4670;
$darkblue400: #426eb1;

$lightpink: #fcf7f7;
$blue50: #f5f6ff;
$blue500: #3f52ff;

$red500: #eb4545;
$red400: #ef6a6a;
$orange500: #ff7d45;
$green500: #4bc27d;

// grey
$grey700: #414449;
$grey600: #61666e;
$grey500: #949aa2;
$grey100: #ecedef;
$green50: #edf9f2;
$calendarPill: #cfebdb;
