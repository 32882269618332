@import 'assets/theme';
@import 'SomeoneHealth/assets/theme';
@import 'CaW/assets/theme';
@import 'Recharge/assets/theme';

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: #2a4670;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
}

.shLoading {
  @extend .loading;
  background-color: $someone-health-pink;
}

.cawLoading {
  @extend .loading;
  background-color: $caw-blue;
}

.easeLoading {
  @extend .loading;
  background-color: transparent;
}

.rechargeLoading {
  @extend .loading;
  background-color: transparent;
}

.selectLoading {
  @extend .loading;
  background-color: transparent;
}

.container {
  height: 100vh;
  overflow-y: scroll;
  background-color: #2a4670;

  @media (max-width: $medium-screen-min-width) {
    height: auto;
  }
}

.shContainer {
  @extend .container;
  background-color: $someone-health-pink;
}

.cawContainer {
  @extend .container;
  background-color: $caw-blue;
}

.easeContainer {
  @extend .container;
  background-color: transparent;
}

.rechargeContainer {
  @extend .container;
  background-color: transparent;
}

.selectContainer {
  @extend .container;
  background-color: transparent;
}

.gatewayContainer {
  height: max-content;
  min-height: 100vh;
  background-color: #2a4670;
  position: relative;
  z-index: 1;
}

.gatewayContainer::after {
  position: absolute;
  content: ' ';
  display: block;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 2;
  height: 100%;
  min-height: 100vh;
  opacity: 0.8;
  background-color: #2a4670;
}

.shGatewayContainer {
  height: 100vh;
  background-size: cover;
  min-height: 440px;
  max-height: 440px;
  background-image: url('~SomeoneHealth/assets/images/background/practitioner-profile-header.png');
  background-position: top;
  background-repeat: no-repeat;
  position: relative;
  z-index: 1;
}

.shGatewayContainer::after {
  position: absolute;
  content: ' ';
  display: block;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 2;
  height: 100vh;
  opacity: 0.8;
  background-size: cover;
  min-height: 440px;
  max-height: 440px;
  background-image: url('~SomeoneHealth/assets/images/background/practitioner-profile-header.png');
  background-position: top;
  background-repeat: no-repeat;
}

.cawGatewayContainer {
  @include loginBackground;

  &::after {
    background-color: transparent;
  }
}

.easeGatewayContainer {
  height: 100vh;
  position: relative;
  z-index: 1;
}

.easeGatewayContainer::after {
  position: absolute;
  content: ' ';
  display: block;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 2;
  height: 100vh;
  opacity: 0.8;
}

.rechargeGatewayContainer {
  height: 100vh;
  position: relative;
  z-index: 1;
}

.rechargeGatewayContainer::after {
  position: absolute;
  content: ' ';
  display: block;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 2;
  height: 100vh;
  opacity: 0.8;
}

.selectGatewayContainer {
  height: 100vh;
  position: relative;
  z-index: 1;
}

.selectGatewayContainer::after {
  position: absolute;
  content: ' ';
  display: block;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 2;
  height: 100vh;
  opacity: 0.8;
}

.content {
  position: relative;

  @media (max-width: $medium-screen-min-width) {
    padding: 0;
  }
}
