@import 'HealthBright/assets/theme';

.greet {
  font-family: $quincyCF;
  color: $health-bright-blue800;
  font-weight: 400;
  font-size: 48px;
  line-height: 44px;
  padding-bottom: $standard-padding * 5;

  @media (max-width: $small-desktop-screen-max-content) {
    font-size: 52px;
    line-height: 44px;
  }

  @media (max-width: $tablet-screen-max-content) {
    font-size: 40px;
    line-height: 44px;
  }
}

.descWrapper {
  display: flex;
  flex-direction: column;
  @include rowGap(16px);

  .desc {
    display: flex;
    flex-direction: column;
    padding-bottom: 16px;

    .label {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      padding-bottom: 16px;
    }
  }
}

.content {
  white-space: pre-line;
  font-size: 20px;
  line-height: 26px;
  color: $health-bright-blue800;
  font-weight: 300;

  @media (max-width: $small-desktop-screen-max-content) {
    font-size: 16px;
    line-height: 21px;
  }
}
