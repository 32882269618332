@import 'SomeoneHealth/assets/theme';

.container {
  height: max-content;
  position: relative;
  background-size: cover;
  min-height: 440px;
  max-height: 440px;
  background-image: url('~SomeoneHealth/assets/images/background/practitioner-profile-header.png');
  background-position: top;
  background-repeat: no-repeat;
}

.contentLayoutWrapper {
  margin-top: 60px;
  padding-bottom: 110px;
  max-width: 1180px;
  gap: 20px;
}

.welcomeMessage {
  font-weight: 600;
  font-size: 28px;
  line-height: 42px;
  letter-spacing: -0.02em;
  color: $white;

  @media (max-width: $small-screen-max-width) {
    font-size: 24px;
    line-height: 36px;
  }
}

.loadingWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  background-color: #ffffffc0;
  position: fixed;
  z-index: 99;
}
