@import 'helm/assets/theme';

.timeLabel {
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-weight: 700;
  font-size: 36px;
  line-height: 36px;

  > div {
    div {
      font-family: $primary-font-stack-medium;
      font-size: 12px;
      line-height: 14px;
    }
  }

  .label {
    font-family: 'Roboto', sans-serif;
  }
}
