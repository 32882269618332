@import 'HealthBright/assets/theme';

.communicationPreferences {
  font-weight: 300;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  gap: 8px;

  .header {
    color: $health-bright-blue800;
    font-size: 18px;
    line-height: 23px;
    font-weight: 400;
  }

  .text {
    line-height: 18px;
  }

  .checkBoxes {
    display: flex;
    flex-direction: column;
    margin-top: 8px;

    .checkBox {
      vertical-align: top;

      input {
        outline: none;
        &:checked::after {
          display: inline-flex;
        }
      }
      margin-right: $standard-padding * 3;
    }

    .checkBoxText {
      height: fit-content;
      display: inline-block;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      margin-top: $standard-padding;
      margin-bottom: $standard-padding * 3;

      a {
        text-decoration: underline;
        color: $health-bright-blue800;
      }
    }
  }
}
