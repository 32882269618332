@import 'SomeoneHealth/assets/theme';

.taskButton {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: $standard-padding * 4 $standard-padding * 5;
  background-color: #f6fafc;
  border-radius: 8px;
  color: $colorGrey700;
  cursor: pointer;
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;

  .text {
    display: flex;
    flex: 1;
  }

  &:hover {
    opacity: 0.8;
  }

  .arrowIcon {
    border-radius: 50%;
    border: solid 1px $someone-health-blue;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $someone-health-blue;
    font-family: 'Material Icons';
    font-size: 20px;
    width: 36px;
    height: 36px;
  }
}
