@import 'SomeoneHealth/assets/theme';

.calendarCardClassName {
  max-width: 420px;
  margin-right: unset !important;
  filter: drop-shadow(4px 8px 12px rgba(0, 0, 0, 0.1));
}

.slotPickerCardClassName {
  max-width: 420px;
}

.calendarWrapperClassName {
  gap: 70px;

  @media (max-width: $small-desktop-screen-max-content + 100px) {
    flex-direction: row !important;
  }

  @media (max-width: 1056px) {
    flex-direction: column !important;
  }

  @media (max-width: $small-screen-max-width) {
    gap: 20px;
  }
}

.filterClassNames {
  max-width: 100% !important;
  align-items: flex-end !important;
}

.content {
  padding-left: 46px;

  @media (max-width: $small-screen-max-width) {
    padding-left: 0;
  }
}

.numberBubbleClassName {
  margin-right: 16px !important;
}
