@import 'assets/theme';

.container {
  width: 100%;
  margin: 0 auto;
  padding: $smp-standard-padding * 4;
}

.maxWidthSmall {
  max-width: $smp-mobile-screen-max-content;
}

.maxWidthMedium {
  max-width: $smp-tablet-screen-max-content;
}

.titleContainer {
  margin: $smp-standard-padding * 2 0;
  display: flex;
  flex-wrap: wrap;
  column-gap: 1rem;
  align-items: center;
  justify-content: space-between;

  .title {
    color: white;
    margin: $smp-standard-padding * 2 0;
    font-size: 28px;
    font-weight: 600;
    line-height: 42px;
  }

  .titleRight {
    margin: $smp-standard-padding * 2 0;
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
  }
}
