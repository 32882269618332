@import 'SomeoneHealth/assets/theme';
@import 'Ease/assets/theme';
@import 'Recharge/assets/theme';
@import 'Select/assets/theme';
@import 'HealthBright/assets/theme';

.toggleSwitch {
  input[type='checkbox'] {
    display: none;

    &:disabled {
      ~ .wrapper {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
  }

  input[type='checkbox']:checked {
    ~ .wrapper::before {
      font-weight: 700;
      color: #3f52ff;
    }

    ~ .wrapper::after {
      font-weight: 600;
      color: #2a4670;
    }

    ~ .wrapper .switch {
      transform: translate3d(6px, 0, 0);
    }
  }

  .switch {
    top: 4px;
    z-index: 1;
    width: calc((100% - 12px) / 2);
    height: 40px;
    border-radius: 25px;
    position: relative;
    display: inline-block;
    background-color: #ffffff;
    transform: translate3d(calc(100% + 6px), 0, 0);
    box-shadow: 0px 4px 2px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s cubic-bezier(0, 1, 0.5, 1);
  }

  .wrapper {
    height: 50px;
    cursor: pointer;
    position: relative;
    border-radius: 25px;
    display: inline-block;
    background-color: #f3f3f3;

    &::before,
    &::after {
      width: 50%;
      z-index: 2;
      font-size: 13px;
      line-height: 50px;
      position: absolute;
      text-align: center;
      text-transform: uppercase;
    }

    &::before {
      left: 0;
      font-weight: 600;
      color: #2a4670;
      content: attr(data-checked);
    }
    &::after {
      right: 0;
      color: #3f52ff;
      font-weight: 700;
      content: attr(data-unchecked);
    }
  }
}

:global(.someone-health-theme) {
  .toggleSwitch {
    .wrapper {
      background-color: #f9f9fa;

      &::before,
      &::after {
        font-weight: 700;
        font-size: 16px;
      }

      &::before {
        color: $someone-health-pink;
      }
      &::after {
        color: #fff;
      }
    }

    .switch {
      background: $someone-health-button_color;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    }

    input[type='checkbox']:checked {
      ~ .wrapper::before {
        color: #fff;
      }

      ~ .wrapper::after {
        color: $someone-health-pink;
      }
    }
  }
}

:global(.health-bright-theme) {
  .toggleSwitch {
    .wrapper {
      background-color: #f9f9fa;

      &::before,
      &::after {
        font-weight: 700;
        font-size: 16px;
      }

      &::before {
        color: $health-bright-blue800;
      }
      &::after {
        color: #fff;
      }
    }

    .switch {
      background: $health-bright-dark-blue;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    }

    input[type='checkbox']:checked {
      ~ .wrapper::before {
        color: #fff;
      }

      ~ .wrapper::after {
        color: $health-bright-blue800;
      }
    }
  }
}

:global(.ease-theme) {
  .toggleSwitch {
    .wrapper {
      background-color: #eeeeee;

      &::before,
      &::after {
        font-weight: 700;
        font-size: 16px;
      }

      &::before {
        color: $ease-dark-grey;
      }
      &::after {
        color: $ease-dark-grey;
      }
    }

    .switch {
      background: $ease-green;
      box-shadow: 0px 4px 8px 0px #0000001a;
    }

    input[type='checkbox']:checked {
      ~ .wrapper::before {
        color: $ease-dark-grey;
      }

      ~ .wrapper::after {
        color: $ease-dark-grey;
      }
    }
  }
}

:global(.recharge-theme) {
  .toggleSwitch {
    .wrapper {
      background-color: $recharge-lighter-blue;

      &::before,
      &::after {
        font-weight: 700;
        font-size: 16px;
      }

      &::before {
        color: $recharge-purple;
      }
      &::after {
        color: $recharge-purple;
      }
    }

    .switch {
      background: $white;
      box-shadow: 0px 4px 8px 0px #0000001a;
    }

    input[type='checkbox']:checked {
      ~ .wrapper::before {
        color: $recharge-purple;
      }

      ~ .wrapper::after {
        color: $recharge-purple;
      }
    }
  }
}

:global(.select-theme) {
  .toggleSwitch {
    .wrapper {
      background-color: $select-light-grey-100;

      &::before,
      &::after {
        font-weight: 700;
        font-size: 16px;
      }

      &::before {
        color: $select-black;
      }
      &::after {
        color: $select-red;
      }
    }

    .switch {
      background: $white;
      box-shadow: 0px 4px 8px 0px #0000001a;
    }

    input[type='checkbox']:checked {
      ~ .wrapper::before {
        color: $select-red;
      }

      ~ .wrapper::after {
        color: $select-black;
      }
    }
  }
}
