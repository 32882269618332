@import 'HealthBright/assets/theme';

$slotChangeViewTablet: $small-desktop-screen-max-content + 220px;
$scrollingSlotView: $mobile-screen-max-content + 100px;

.contentLayout {
  background: $health-bright-green;
  padding-top: 40px;
  padding-bottom: 40px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.bookingContainer {
  padding: 24px 16px;
  background: $health-bright-light-grey;

  @media (min-width: ($small-desktop-screen-max-content + 1px)) {
    height: 200px;
    margin-bottom: 100px;
    padding: 0 16px 20px 16px;
  }

  &.isAdvisorySession {
    padding: 24px;
  }

  .bookingContent {
    display: flex;

    @media (max-width: $small-desktop-screen-max-content) {
      display: block;
    }

    @media (max-width: $scrollingSlotView) {
      display: block;
    }

    .slotWrapper {
      display: flex;
      @include columnGap(30px);

      @media (max-width: $small-desktop-screen-max-content) {
        position: absolute;
      }
    }

    .middleContent {
      display: flex;
      width: 100%;
      padding: 0 30px;

      @media (max-width: $small-desktop-screen-max-content) {
        flex-direction: column-reverse;
        gap: 30px;
        padding: 0;
      }
    }

    .bookingDesc {
      display: flex;
      flex-direction: column;
      color: $health-bright-blue800;

      .bookingTitle {
        font-weight: 400;
        font-size: 24px;
        line-height: 32px;
        font-family: $quincyCF;
      }

      .bookingDescSummary {
        font-weight: 300;
        font-size: 18px;
        line-height: 23px;
        margin-top: 10px;
      }

      .bookingOnLabel {
        font-weight: 400;
        font-size: 18px;
        line-height: 23px;
        font-weight: 700;
      }

      .importantInfoContainer {
        margin-top: 20px;
        font-weight: 400;

        .link {
          font-weight: 400;
          color: $health-bright-blue800;
          text-decoration: underline;
        }
        .importantInfoTitle {
          font-size: 14px;
          line-height: 18px;
        }

        .importantInfoContent {
          padding-left: 20px;
          display: flex;
          flex-direction: column;
          gap: 10px;
          margin-top: 8px;
        }

        .unavailableItems {
          display: flex;
          margin-top: 5px;
          gap: 40px;
        }

        .unavailableItem {
          display: flex;
          align-items: center;
        }

        .closeIcon {
          font-size: 18px;
          color: $health-bright-blue800;
        }
      }
    }

    .slotContainer {
      display: flex;
      flex-direction: column;

      @media (max-width: $scrollingSlotView) {
        width: auto;
        align-items: initial;
      }

      .blocked {
        background: $health-bright-light-green;
        border: 2px solid $health-bright-blue800;
        font-weight: 400;
        font-size: 16px;
        line-height: 21px;
        color: $health-bright-blue800;
        padding: $standard-padding;
        border-radius: 4px;
        max-width: max-content;
        margin-bottom: 16px;
      }

      .slotWrapper {
        display: flex;
        max-width: 100%;
        align-items: center;
        row-gap: 16px;
        @include columnGap(16px);
      }

      .slotLabelMobile {
        color: $white;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        padding-left: 40px;
        padding-top: 4px;
        display: none;

        @media (max-width: $slotChangeViewTablet) {
          display: block;
          padding-left: 0;
          font-size: 10px;
          line-height: 13px;
        }
      }
    }

    .continueBtnWrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .continueBtn {
        min-width: 250px;
      }

      @media (max-width: $small-desktop-screen-max-content) {
        align-items: center;
        margin-top: 30px;
      }

      .continueLabel {
        color: $health-bright-blue800;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        padding-top: 4px;
      }
    }
  }

  .consultPreferenceWrapper {
    margin: auto;
    padding-left: 10px;
    min-width: 160px;

    @media (max-width: $small-desktop-screen-max-content) {
      display: flex;
      padding-left: 140px;
      margin: unset;
    }
  }

  .consultPreference {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $health-bright-blue800;
    justify-content: center;
    text-align: center;

    .consultPreferenceLabel {
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
    }

    .consultPreferenceButton {
      width: fit-content;
      padding: 0 5px;
      background: $white;
      border: 1.1383px solid #d9d9d9;
      margin-top: 10px;

      .indicator {
        box-shadow: unset;
        border: 1.87719px solid $health-bright-dark-blue;
        background-color: $health-bright-dark-blue;
      }

      .label {
        padding: 0 !important;
        color: $health-bright-blue800 !important;
      }

      .labelActive {
        padding: 0 !important;
        color: $white !important;
      }
    }

    .optionButton1 {
      min-width: 50px;
    }

    .optionButton2 {
      min-width: 100px;
    }

    .optionButton3 {
      min-width: 150px;
    }

    .selectedPreference {
      margin-top: 15px;
      font-size: 20px;
      font-weight: 400;
    }
  }
}
