@import 'SomeoneHealth/assets/theme';
@import 'Ease/assets/theme';
@import 'Recharge/assets/theme';
@import 'Select/assets/theme';
@import 'HealthBright/assets/theme';

.container {
  display: flex;
  cursor: pointer;

  .checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
    appearance: none;
    margin: 0;
    height: 20px;
    width: 20px;
    min-width: 20px;
    background-color: transparent;
    border: 2px solid #d5d5d5;
    border-radius: 4px;
    color: #d5d5d5;
    cursor: pointer;
    outline: none;
    transition: all 0.1s linear;

    &:after {
      content: '';
      width: 6px;
      height: 12px;
      position: relative;
      top: -1px;
      border: solid #fff;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }

    &:checked {
      font-size: 12px;
      line-height: 16px;
      font-weight: 800;
      color: #fff;
      background-color: #3f52ff;
      border: 2px solid #3f52ff;

      &:after {
        display: flex;
      }
    }
  }

  .checkBoxLabel {
    margin-left: 8px;
    user-select: none;
  }
}

.disabledContainer {
  @extend .container;
  cursor: not-allowed;

  .checkbox {
    cursor: not-allowed;
    background-color: #bdbdbd !important;
    border: 2px solid #bdbdbd !important;
  }

  .checkBoxLabel {
    color: #bdbdbd;
  }
}

:global(.someone-health-theme) {
  .checkbox {
    &:checked {
      background-color: $someone-health-pink;
      border-color: $someone-health-pink;
    }

    &:focus {
      outline: unset !important;
    }
  }
}

:global(.ease-theme) {
  .checkbox {
    &:checked {
      background-color: $ease-green;
      border-color: $ease-green;

      &:after {
        border-color: $ease-dark-grey;
      }
    }

    &:focus {
      outline: unset !important;
    }
  }
}

:global(.recharge-theme) {
  .checkbox {
    &:checked {
      background-color: $recharge-purple;
      border-color: $recharge-purple;
    }

    &:focus {
      outline: unset !important;
    }
  }
}

:global(.select-theme) {
  .checkbox {
    border-radius: 0;

    &:checked {
      background-color: $select-red;
      border-color: $select-red;
    }

    &:focus {
      outline: unset !important;
    }
  }
}

:global(.health-bright-theme) {
  .checkbox {
    height: 15px;
    width: 15px;
    min-width: 15px;
    border-radius: 2px;
    border-color: $health-bright-dark-green;
    margin-top: 3px;

    &::after {
      width: 5px;
      top: -2px;
    }

    &:checked {
      background-color: $health-bright-dark-green;
      border-color: $health-bright-dark-green;
    }

    &:focus {
      outline: unset !important;
    }
  }
}
