@import 'helm/assets/theme';

.card {
  max-width: 1068px;
  font-family: 'Nunito', normal;
  font-weight: 600;
  font-size: 16px;
  background-color: $blue50;
  border-radius: 8px;
  padding: $standard-padding * 5;
  margin-top: $standard-padding * 5;

  .title {
    font-size: 28px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .row {
    flex: 1;
    display: flex;
    grid-auto-rows: auto;
    margin-left: ($standard-padding * 2 - 2);
    margin-right: -($standard-padding * 6 - 2);
    @media (max-width: $tablet-screen-max-content) {
      display: block;
    }
    .col {
      margin: 0 $standard-padding * 3;
      min-height: 68px;
      width: 26%;

      @media (max-width: $tablet-screen-max-content) {
        width: auto;
      }

      &.fieldError,
      .fieldError {
        font-size: 12px;
        color: #ff4d4f;

        input {
          border-bottom: 1px solid #ff4d4f !important;
        }
      }
    }

    .colLonger {
      flex: 1;
    }
  }

  .input {
    padding-left: $standard-padding * 4;
    margin-left: -$standard-padding * 4 + 2;
    background-color: transparent;
    margin-top: 27px;
    height: 35px;
  }

  .mobileInput {
    padding-left: $standard-padding * 4 - 2;
    margin-left: -$standard-padding * 4 + 2;
    margin-bottom: 0px;
    background-color: transparent;
    border-bottom: 1px solid #c4c4c4 !important;
  }

  .select {
    margin-left: -$standard-padding * 4;
    background-color: transparent;
    margin-top: $standard-padding * 3 - 1;
  }

  .selectLabel {
    margin-left: $standard-padding * 4;
    color: #818e9b !important;
    font-weight: 600;
    font-size: 12px;
    margin-top: 6px;
    margin-bottom: -16px;
  }

  .mobileLabel {
    color: #818e9b !important;
  }

  .error {
    text-align: left;
  }

  .linkBtn {
    color: $helm-primary;
    background-color: transparent;
    box-shadow: none;
    padding: 0;
    text-align: left;
    margin-left: -16px;

    i {
      font-size: 14px;
      margin-right: 10px;
    }

    &:hover {
      opacity: 0.8;
    }
  }

  .buttonWrapper {
    display: flex;
    align-items: center;
    column-gap: 12px;
    margin-top: $standard-padding * 5;
  }

  .addContactBtn {
    padding: $standard-padding * 4;
    box-shadow: none;

    @media (max-width: $tablet-screen-max-content) {
      margin-top: $standard-padding * 3;
    }

    &:hover {
      opacity: 0.8;
    }
  }

  .cancelBtn {
    padding: $standard-padding * 4;
    box-shadow: none;
    background-color: transparent;
    color: $helm-primary;

    i {
      font-size: 24px;
      margin-right: 10px;
      vertical-align: top;
    }

    &:hover {
      opacity: 0.8;
    }
  }
}
