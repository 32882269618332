@import 'HealthBright/assets/theme';

.container {
  display: flex;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 360px;
  background-image: url(~HealthBright/assets/images/background/quote.png);

  .quoteContent {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 360px;

    .quote {
      font-family: $quincyCF;
      white-space: pre-line;
      font-weight: 400;
      font-size: 36px;
      line-height: 47px;
      text-align: center;
      letter-spacing: -0.02em;
      color: $health-bright-blue800;
      max-width: 540px;

      @media (max-width: $tablet-screen-max-content) {
        font-size: 24px;
        line-height: 32px;
      }
    }

    .commaContainer {
      display: flex;
      position: relative;
      left: -15px;

      .comma {
        width: 20px;
        height: 30px;

        @media screen and (max-width: $tablet-screen-max-content) {
          width: 16px;
        }
      }
    }

    .endCommaContainer {
      @extend .commaContainer;
      justify-content: flex-end;
      left: 15px;
    }
  }
}
