@import 'assets/theme';
@import 'SomeoneHealth/assets/theme';

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: #2a4670;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
}

.container {
  height: 100vh;
  overflow-y: scroll;
  background-color: #2a4670;
}

.content {
  position: relative;

  @media (max-width: $medium-screen-min-width) {
    padding: 0;
  }
}

:global(.someone-health-theme) {
  .loading {
    background-color: $someone-health-pink;
  }
  .container {
    background-color: $someone-health-pink;
  }
}

:global(.ease-theme) {
  .loading {
    background: none;
  }
  .container {
    background: none;
  }
}

:global(.recharge-theme) {
  .loading {
    background: none;
  }
  .container {
    background: none;
  }
}

:global(.select-theme) {
  .loading {
    background: none;
  }
  .container {
    background: none;
  }
}
