@import 'HealthBright/assets/theme';

.container {
  display: flex;
  flex-direction: column;
  color: $health-bright-blue800;

  .qualification {
    font-size: 20px;
    display: inline-flex;
    margin-left: 18px;
    .qualificationItem {
      margin-left: 18px;
    }
  }

  .topContent {
    display: flex;
    align-items: baseline;
    column-gap: 12px;

    .name {
      font-family: $quincyCF;
      font-weight: 400;
      font-size: 36px;
      line-height: 42px;
      letter-spacing: -0.02em;
      color: $health-bright-blue800;

      @media (max-width: $tablet-screen-max-content) {
        font-size: 24px;
        line-height: 32px;
      }
    }

    .pronouns {
      font-weight: 400;
      font-size: 20px;
      line-height: 23px;
      color: $health-bright-blue800;
      margin-left: 18px;
    }
  }

  .middleContent {
    display: flex;
    align-items: center;
    margin: 5px 0 26px;
    font-size: 20px;

    .tagCollection {
      display: flex;
      align-items: center;
      @include columnGap(12px);
      flex-wrap: wrap;
    }

    .inLabel {
      margin: 0 12px 8px 0;
      font-weight: 500;
      font-size: 16px;
      line-height: 21px;
      color: $health-bright-blue800;
    }

    .tag {
      display: flex;
      align-items: center;
      font-size: 14px;
      line-height: 18px;
      font-weight: 500;
      text-align: center;
      color: $health-bright-blue800;
      background: $health-bright-light-yellow;
      border-radius: 40px;
      padding: 8px 10px;
      margin-bottom: 8px;

      .callIcon {
        font-size: 20px;
        margin-right: 8px;

        @media (max-width: $tablet-screen-max-content) {
          margin-right: 0;
        }
      }
    }
  }

  .aboutMeSection {
    display: flex;
    flex-direction: column;

    .aboutMeLabel {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: $health-bright-blue800;
      padding-bottom: 8px;
    }

    .aboutMe {
      white-space: pre-line;
      font-weight: 300;
      font-size: 16px;
      line-height: 21px;

      @media (max-width: $tablet-screen-max-content) {
        font-size: 14px;
        line-height: 18px;
      }
    }
  }

  .aboutMe :global {
    .readMore {
      color: $health-bright-blue800;
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      text-decoration: underline;
    }
  }
}
