@import 'HealthBright/assets/theme';

.containerWrapper {
  display: flex;
  flex-direction: column;
  height: 100vh;

  .background {
    height: 100%;
    width: 56%;
    background: url('~HealthBright/assets/images/background/hbTokenAccessImage.png') no-repeat;
    background-size: cover;
    background-position: 30%;
    overflow: hidden;
    position: absolute;
    right: 0;
    border-radius: 30px 0 0 30px;
    z-index: 0;

    @media (max-width: $medium-large_screen-min-width) {
      display: none;
    }
  }

  .contentWrapper {
    height: 100%;
  }

  .content {
    display: flex;
    height: 100%;

    .leftContent {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      height: 100%;
      flex: 1 2 44%;

      @media screen and (min-width: ($medium-large_screen-min-width + 1px)) {
        padding-right: 16px;
      }

      .formContent {
        @include rowGap(26px);
        height: 100%;
        align-self: center;
        max-width: 462px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        @media screen and (max-width: ($medium-large_screen-min-width)) {
          justify-content: initial;
          padding-top: 50px;
        }
      }

      .welcomeText {
        font-size: 32px;
        font-weight: 400;
        line-height: 38px;
        color: $health-bright-blue800;
        text-align: left;
      }

      .text {
        font-size: 16px;
        line-height: 24px;
        font-weight: 300;
        color: $health-bright-blue800;
        text-align: left;
      }

      .smallText {
        @extend .text;
        font-size: 14px;
        line-height: 20px;
        text-align: center;

        @media screen and (max-width: ($medium-small-screen-min-width)) {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
      }

      .visitEmail {
        font-weight: 400;
      }

      .link {
        text-decoration: underline;
        font-size: 14px;
        color: $health-bright-dark-blue;
        font-weight: 400;
        cursor: pointer;
        width: max-content;
      }

      .signInButton {
        margin: 8px 0;
      }

      .inputContainerClassName {
        margin-bottom: unset;
      }

      .inputClassName {
        width: 40px;
        height: 48px;
        background: #fff;
        border: 1px solid #898d8d;
        box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
        border-radius: 4px;
        font-weight: 400;
        font-size: 28px;
        line-height: 60px;
        letter-spacing: -0.02em;
        color: #898d8d;
        margin-top: unset;

        @media screen and (max-width: 1150px) {
          font-size: 32px;
          width: 100%;
        }

        @media screen and (max-width: $medium-small-screen-min-width) {
          height: 36px;
          padding: 0;
        }
      }

      .inputError {
        border: 1px solid #d54141 !important;
      }

      .errorClassName {
        text-align: left;
      }
    }

    .rightContent {
      flex: 1 2 56%;
      height: 100%;
      z-index: 99;

      @media screen and (max-width: $medium-large_screen-min-width) {
        display: none;
      }

      .infoBox {
        background-color: rgba(255, 255, 255, 0.3);
        border: 1px solid rgba(255, 255, 255, 0.5);
        padding: 32px 24px 29px 24px;
        font-size: 24px;
        position: absolute;
        margin-left: 50px;
        margin-right: 50px;
        bottom: 80px;
        color: $health-bright-blue800;
        line-height: 45px;
        font-weight: 300;
        display: flex;
        flex-direction: column;
        gap: 20px;

        .welcomeText {
          font-size: 40px;
          font-family: $quincyCF;
          font-weight: 500;
        }
      }
    }
  }
}

.footer {
  height: 52px;
  font-weight: 300;

  @media (min-width: ($tablet-screen-max-content + 1px)) {
    padding: 0 14px;
  }
}
