@import 'HealthBright/assets/theme';

.container {
  display: flex;

  .borderFrame {
    padding: 12px;
    border-radius: 50%;
  }

  .image {
    width: 100%;
    border-radius: 50%;
  }
}
