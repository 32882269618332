@import 'helm/assets/theme';

.container {
  background-color: #f5f5f5;
  border-radius: 0.5rem;
  padding: 0.5rem 0;
  overflow-x: hidden;
  overflow-y: auto;
  height: 169px;
}

.hiddenInMobile {
  @media (max-width: $mobile-screen-max-content) {
    display: none;
  }
}

.loading {
  padding: 0 0.5rem;
}

.title {
  font-weight: 600;
  padding: 0 0.5rem;
}

.address {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  padding: 0 0.5rem;

  &:hover {
    background-color: #d7d7d7;
  }
}
