@import 'helm/assets/theme';

.ant-progress-circle-path {
  transform: scaleX(-1);
  transform-origin: center;
}

.ant-progress-circle-trail {
  stroke: #ecedef !important;
}

.recharge-theme .ant-progress {
  font-variant: normal;
}
