@import 'assets/theme';
@import 'SomeoneHealth/assets/theme';

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: #2a4670;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
}

.container {
  height: 100vh;
  overflow-y: auto;
  background-color: #2a4670;

  .cordsDoneContainer {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
}

.content {
  position: relative;
  align-items: center;
  margin-top: 80px;

  @media (max-width: $medium-screen-min-width) {
    justify-content: center;
    min-height: 100%;
    margin-top: 0;
    padding: 0;
  }
}

.contentContainer {
  width: 100%;
  background-color: white;
  background-size: 100%;
  background-repeat: no-repeat;
  border-radius: $standard-padding * 2;

  @media (max-width: $medium-screen-min-width) {
    padding: 16px;
    border-radius: 0;
  }
}

:global(.someone-health-theme) {
  .loading {
    background-color: $someone-health-pink;
  }
  .container {
    background-color: $someone-health-pink;
  }
}

:global(.ease-theme) {
  .loading {
    background: none;
  }
  .container {
    background: none;
  }
}

:global(.recharge-theme) {
  .loading {
    background: none;
  }
  .container {
    background: none;
  }
}

:global(.select-theme) {
  .loading {
    background: none;
  }
  .container {
    background: none;
  }
}
