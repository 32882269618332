// Base
$primary-font-stack-medium: 'stolzl';

// Font size
$font-extra-tiny: 10px;
$font-tiny: 12px;
$font-small: 14px;
$font-standard: 16px;
$font-medium-large: 18px;
$font-large: 24px;
$font-extra-large: 30px;
$font-extreme-large: 60px;

// Font weight
$font-weight-light: 200;
$font-weight-regular: 300;
$font-weight-bold: 400;
$font-weight-boldest: 800;

$stolzl: 'Stolzl';
$quincyCF: 'Quincy CF';

@font-face {
  font-family: $stolzl;
  src: url(~HealthBright/assets/theme/typography/stolzl/Stolzl-Light.woff2) format('woff2');
  font-weight: 300;
}

@font-face {
  font-family: $stolzl;
  src: url(~HealthBright/assets/theme/typography/stolzl/Stolzl-Regular.woff2) format('woff2');
  font-weight: 400;
}

@font-face {
  font-family: $stolzl;
  src: url(~HealthBright/assets/theme/typography/stolzl/Stolzl-Medium.woff2) format('woff2');
  font-weight: 500;
}

@font-face {
  font-family: $stolzl;
  src: url(~HealthBright/assets/theme/typography/stolzl/Stolzl-Bold.woff2) format('woff2');
  font-weight: 700;
}

// Quincy CF font
@font-face {
  font-family: $quincyCF;
  src: url(~HealthBright/assets/theme/typography/quincyCF/QuincyCF-Thin.woff2) format('woff2');
  font-weight: 200;
}

@font-face {
  font-family: $quincyCF;
  src: url(~HealthBright/assets/theme/typography/quincyCF/QuincyCF-Light.woff2) format('woff2');
  font-weight: 300;
}

@font-face {
  font-family: $quincyCF;
  src: url(~HealthBright/assets/theme/typography/quincyCF/QuincyCF-Regular.woff2) format('woff2');
  font-weight: 400;
}

@font-face {
  font-family: $quincyCF;
  src: url(~HealthBright/assets/theme/typography/quincyCF/QuincyCF-Medium.woff2) format('woff2');
  font-weight: 500;
}

@font-face {
  font-family: $quincyCF;
  src: url(~HealthBright/assets/theme/typography/quincyCF/QuincyCF-Bold.woff2) format('woff2');
  font-weight: 700;
}

@font-face {
  font-family: $quincyCF;
  src: url(~HealthBright/assets/theme/typography/quincyCF/QuincyCF-ExtraBold.woff2) format('woff2');
  font-weight: 800;
}
