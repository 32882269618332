@import 'HealthBright/assets/theme';

.modalContainer {
  @media (min-width: ($tablet-screen-max-content + 1)) {
    width: 700px !important;
  }

  .container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    font-family: $stolzl;
    font-weight: 300;

    div {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }

    span {
      color: $health-bright-blue800;
      font-size: 16px;
      line-height: 21px;
    }

    .boldText {
      color: $health-bright-blue800;
      font-size: 16px;
      line-height: 21px;
    }

    .title {
      color: $health-bright-blue800;
      font-size: 20px;
      line-height: 26px;
      font-weight: 400;
    }

    .text {
      color: $colorBlue500;
      font-size: 16px;
      line-height: 21px;
      text-decoration: underline;
      font-weight: 400;
    }
  }
}
