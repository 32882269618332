@import 'SomeoneHealth/assets/theme';
@import 'helm/assets/theme';
@import 'Recharge/assets/theme';

.container {
  max-width: 1120px;
  width: 100%;

  @media (max-width: $medium-large_screen-min-width) {
    display: flex;
    flex-direction: column;
  }

  .content {
    display: flex;
    width: 100%;
    justify-content: space-between;
    gap: 30px;

    @media (max-width: $medium-large_screen-min-width) {
      flex-wrap: wrap;
      flex-direction: column-reverse;
    }
  }

  .leftContent {
    flex: 1 1;
    width: 100%;
    max-width: 540px;

    @media (max-width: $small-screen-max-width) {
      min-width: unset;
    }
  }

  .rightContent {
    @media (max-width: $medium-large_screen-min-width) {
      display: flex;
      padding-top: unset;
    }
  }
}

.fieldContainer + .fieldContainer:not(.date, .selectDropdown) {
  margin-top: 25px;
}

.selectDropdown {
  margin-top: 5px;
}

.fieldContainer {
  .fieldDescription {
    margin-top: 4px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #202225;
  }
}

.nameIsRegisteredClass {
  &:checked {
    ~ div {
      color: $someone-health-pink;
    }
  }
}

.checkNumberStatus {
  margin-top: 25px;
}

.medicareIntro {
  background: #edf1ff;
  padding: 12px 16px;
  max-width: 500px;

  @media (max-width: $medium-large_screen-min-width) {
    max-width: 100%;
  }

  @media (max-width: $medium-large_screen-min-width) {
    max-width: 540px;
  }

  .title {
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: #202225;
    display: flex;
    gap: 13px;

    .infoIcon {
      font-size: 18px;
      color: $someone-health-blue;
    }
  }

  .medicareImg {
    margin-top: 16px;
    max-width: 400px;
    padding: 12px 10px 12px 30px;
    width: 100%;

    @media (max-width: $medium-large_screen-min-width) {
      padding: 12px 0 0 30px;
      margin: unset;
    }
  }
}

:global(.helm-theme) {
  .nameIsRegisteredClass {
    &:checked {
      ~ div {
        color: $helm-blue;
      }
    }
  }
}

:global(.recharge-theme) {
  .nameIsRegisteredClass {
    &:checked {
      ~ div {
        color: $recharge-purple;
      }
    }
  }
}
