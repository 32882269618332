@import 'HealthBright/assets/theme';

.footerContainer {
  background-color: $health-bright-dark-green;
}

.container {
  padding-top: 16px;
  padding-bottom: 16px;

  .content {
    display: flex;
    justify-content: space-between;
    margin-bottom: 26px;

    #aboutUsWrapper {
      font-size: 24px;
      color: $white;

      @media (max-width: 564px) {
        font-size: 20px;
      }
    }

    .textContainer {
      width: 45%;
      font-weight: 300;

      @media (max-width: 564px) {
        padding-top: 16px;
        width: 100%;
        font-size: 18px;
      }

      #aboutUsContent {
        line-height: 32px;
      }

      #quickLinksHeader {
        margin: 0px 0px 18px 0px;
      }

      #getInTouchContent,
      #getInTouchContentLast {
        position: relative;
        margin-bottom: 12px;
        padding-left: 29px;

        .icon {
          position: absolute;
          left: 0;
          top: 6.5px;
          z-index: 1;
          width: 15px;
          text-align: center;
          line-height: inherit;
          color: #f46386;
        }

        .text {
          display: flex;
          align-items: flex-start;
        }
      }

      #getInTouchContentLast {
        margin-bottom: 0;
      }

      .header {
        color: #ffffff;
        font-size: 17px;
        font-weight: 300;
        line-height: 35px;
        margin: 0px 0px 25px 0px;
      }

      .list {
        list-style-type: none;
        margin-bottom: 60px;
        padding: 0;
      }

      .text {
        font-size: 20px;
        font-weight: 300;
        margin-bottom: 0;
        line-height: 28px;
        letter-spacing: 0;
        margin-bottom: 4px;
        a {
          text-decoration: underline;
        }
        .textWhite {
          color: #ffffff;
          line-height: 28px;
        }
      }

      .finalText {
        color: #ffffff;
        font-size: 14px;
      }

      .logo {
        margin-top: 30px;
        max-width: 100%;
        cursor: pointer;
      }
    }

    @media (max-width: 564px) {
      flex-wrap: wrap;
    }
  }
}
