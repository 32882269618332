@import 'SomeoneHealth/assets/theme';

.container {
  display: flex;
  flex-direction: column;
  background: $someone-health-light-blue;
  padding: 24px 34px;
  border-radius: 8px;

  @media (max-width: $small-screen-max-width) {
    padding: 20px 16px;
  }

  .content {
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: $medium-large_screen-min-width) {
      flex-direction: column-reverse;
      gap: 10px;
    }
  }

  .headerContent {
    display: flex;
    flex-direction: column;

    .changeAppointment {
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: 18px;
      line-height: 23px;
      color: $someone-health-pink;
      padding-bottom: 16px;
      width: max-content;

      @media (max-width: $medium-large_screen-min-width) {
        display: none;
      }

      .icon {
        font-size: 18px;
        margin-right: 5px;
      }
    }

    .greet {
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
      letter-spacing: -0.02em;
      display: flex;
      padding-left: 30px;

      @media (max-width: $medium-large_screen-min-width) {
        padding-left: 0;
      }

      .name {
        padding-left: 5px;
        color: $someone-health-blue;
      }
    }

    .greetDesc {
      font-size: 14px;
      line-height: 18px;
      color: $someone-health-grey700;
      padding: 16px 0 16px 30px;
      font-weight: 500;

      @media (max-width: $medium-large_screen-min-width) {
        padding: 8px 0;
      }
    }
  }

  .leftContent {
    display: flex;
    flex-basis: 60%;
    flex-direction: column;
    gap: 20px;

    .title {
      font-weight: 700;
      font-size: 16px;
      line-height: 21px;
      color: $colorGrey700;
    }

    .content {
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
    }

    .importantInfo {
      display: flex;
      flex-direction: column;
      gap: 12px;
      padding-left: 30px;

      .chargesWrapper {
        display: flex;
        align-items: center;
        gap: 28px;
        padding-left: 40px;
      }
      .info {
        color: $colorGrey700;
        font-size: 16px;
        font-weight: 500;
        line-height: 21px;
      }
      .totalCharges {
        color: $colorGrey700;
        font-size: 24px;
        font-weight: 700;
        line-height: 32px;
      }
    }

    .submitButton {
      width: 100%;
      max-width: 540px;

      @media (min-width: ($small-desktop-screen-max-content)) {
        margin-left: 30px;
      }
    }
  }

  .rightContent {
    display: flex;
    flex-basis: 30%;

    .notice {
      display: flex;
      align-items: center;
      gap: 25px;
      flex-direction: column;

      @media screen and (max-width: $medium-large_screen-min-width) {
        flex: 1;
        flex-direction: row-reverse;
        justify-content: space-between;
      }
    }

    @media screen and (max-width: $medium-large_screen-min-width) {
      .timeLabel {
        display: flex;
        flex-direction: row;
        justify-content: center;
        font-weight: 700;
        font-size: 20px;
        line-height: 26px;

        > div {
          display: flex;
          flex-direction: column;
          align-items: center;
          div {
            font-weight: 500;
            font-size: 10px;
            line-height: 13px;
          }
        }
      }
    }

    .noticeMsg {
      max-width: 260px;
      font-weight: 500;
      font-size: 16px;
      line-height: 21px;

      div {
        text-align: center;

        @media screen and (max-width: $medium-large_screen-min-width) {
          text-align: left;
        }
      }

      @media screen and (max-width: $medium-large_screen-min-width) {
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        max-width: unset;
      }
    }
  }
}

.paymentPolicyWrapper {
  padding-left: 30px;
  @media (max-width: $medium-large_screen-min-width) {
    padding-left: 0;
  }
}
