@import 'Recharge/assets/theme';
@import 'Select/assets/theme';
@import 'assets/theme';

.container {
  top: 30px;

  @media (max-width: $small-screen-max-width) {
    width: 100vw !important;
    max-width: 100vw;
    margin: 0;
    padding: 0;
    top: 20px;
  }

  :global {
    .ant-modal-content {
      border-top: 10px solid #9342f7;
      border-radius: 10px 10px 8px 8px;

      .ant-modal-close {
        svg {
          width: 14px;
          height: 14px;
          fill: #343434;
        }
      }

      .ant-modal-header {
        padding: $standard-padding * 2 $standard-padding * 2.5;

        .ant-modal-title {
          padding: $standard-padding 0;
          font-weight: 600;
          font-size: 24px;
          color: #343434;
        }
      }

      .ant-modal-body {
        padding: 0 !important;
      }

      @media (max-width: $small-screen-max-width) {
        width: 100%;
        height: 100%;
        border-color: #9342f7;
        border-radius: 10px 10px 0 0;
        background-color: white;

        .ant-modal-header {
          .ant-modal-title {
            font-size: 20px;
          }
        }
      }
    }
  }
}

.currentAppointmentDetails {
  padding: $standard-padding * 2.5;
  padding-bottom: $standard-padding;
  border-bottom: 1px solid #c4c4c4;

  .detailWrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
  }

  .detailContainer {
    &:not(:last-child) {
      margin-bottom: $standard-padding * 2.5;
    }

    .label {
      color: #949aa2;
      font-weight: 600;
      font-size: 14px;
    }

    .value {
      display: flex;
      flex-wrap: wrap;
      color: #343434;
      font-weight: 600;
      font-size: 18px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      .divider {
        border-right: 1px solid #c4c4c4;
      }
    }
  }

  @media (max-width: $small-screen-max-width) {
    padding: 8px;

    .detailContainer {
      &:not(:last-child) {
        margin-bottom: $standard-padding;
      }

      .label {
        font-size: 12px;
      }

      .value {
        font-size: 14px;
      }
    }
  }
}

.timeSlotsContainer {
  padding: $standard-padding * 2.5 0;

  .title {
    padding: 0 $standard-padding * 2.5;
    color: #414449;
    font-weight: 600;
    font-size: 18px;
  }

  @media (max-width: $small-screen-max-width) {
    padding: 10px 0;

    .title {
      font-size: 14px;
      padding: 0 $standard-padding;
      padding-bottom: 5px;
    }

    .availableTimeSelect {
      height: 350px;
      width: 100%;
    }
  }
}

.informationBoxContainer {
  padding: $standard-padding * 2.5;

  .backgroundContainer {
    padding: $standard-padding * 2.5;
    border-radius: 4px;

    .messageContainer {
      display: flex;
      @include columnGap($standard-padding * 1.5);

      .warningIcon {
        color: #ff7d45;
      }
      .infoIcon {
        color: #2a4670;
      }

      .descriptionContainer {
        @include rowGap($standard-padding * 3.5);
        .description {
          font-weight: 600;
          font-size: 18px;
          color: #414449;

          .phoneNumber {
            color: $colorBlue1;
          }
        }
      }
    }
  }

  .warning {
    background: #fff2ec;
  }
  .info {
    background: #f5f6ff;
  }
}

.footer {
  padding: $standard-padding * 2.5;
  background-color: #f5f6ff;
  box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 0 0 8px 8px;

  .title {
    color: #61666e;
    font-weight: 600;
    font-size: 14px;
    margin-bottom: $standard-padding * 2.5;
  }

  .content {
    justify-content: space-between;
    align-items: center;

    @media (min-width: ($small-screen-max-width + 1px)) {
      display: flex;
    }

    .timeContainer {
      color: $main-blue;
      font-weight: 700;
      font-size: 14px;
      text-align: center;

      display: flex;
      align-items: center;
      justify-content: center;

      @media (max-width: $small-screen-max-width) {
        margin-bottom: $standard-padding;
      }

      .to {
        color: #949aa2;
        font-weight: 600;
        font-size: 12px;
        margin: 0 $standard-padding * 4;
      }
    }

    .rescheduleButton {
      @media (max-width: $small-screen-max-width) {
        width: 100%;
      }
    }
  }

  @media (max-width: $small-screen-max-width) {
    padding: $standard-padding * 2 $standard-padding;

    .title {
      margin-bottom: $standard-padding;
      font-size: 12px;
    }
  }
}

:global(.ease-theme) {
  :global {
    .ant-modal-content {
      border-top-color: #564197;
    }
  }

  .footer .content .rescheduleButton {
    text-transform: uppercase;
  }
}

:global(.recharge-theme) {
  .container :global {
    .ant-modal-content {
      border-top-color: $recharge-yellow;
    }
  }

  .footer .content .timeContainer {
    color: $recharge-black;
  }
}

:global(.select-theme) {
  .container :global {
    .ant-modal-content {
      border-top-color: #fcbf49;
    }
  }

  .footer .content .timeContainer {
    color: $select-black;
  }
}
