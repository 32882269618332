@import 'assets/theme';

.container {
  max-width: 500px;
  background-color: white;
  background-image: url(~assets/images/background-circles.png);
  background-size: 100%;
  background-repeat: no-repeat;
  padding: 32px;
  border-radius: $standard-padding * 2;

  @media (max-width: $medium-screen-min-width) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100vh;
    padding: 16px;
    border-radius: 0;
  }
}

.header {
  margin-bottom: 16px;
  font-size: 20px;
  font-weight: bold;
}

.description {
  margin-bottom: 16px;
  font-size: 16px;
  font-weight: 600;
}

.buttonsContainer {
  display: flex;
  flex-direction: column;
  max-width: 500px;

  .otpButton {
    margin: 0;
    margin-bottom: 16px;
    box-shadow: none;
  }

  .or {
    display: flex;
    justify-content: center;
    margin-bottom: 16px;
    position: relative;

    &:before {
      position: absolute;
      top: 50%;
      width: 100%;
      border-top: 1px solid #d4d4d4;
      content: '';
    }

    .text {
      padding: 0 16px;
      background: #ffffff;
      z-index: 1;
    }
  }

  .loginButton {
    margin: 0;
  }
}
