@import 'SomeoneHealth/assets/theme';

.container {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  width: 100%;
  max-width: 420px;
  padding: 16px 81px 18px 16px;
  gap: 16px;
  user-select: none;
  cursor: pointer;
  justify-content: space-between;
  color: $someone-health-blue;
  background: $white;
  border: 1px solid $someone-health-blue;

  @media (max-width: $small-screen-max-width) {
    padding: 16px;
  }

  &.selected {
    color: $white;
    background: $someone-health-blue;
    box-shadow: inset 0 4px 12px rgba(241, 223, 87, 0.2);
  }

  &.disabled {
    cursor: not-allowed;
  }

  .appointmentTypeInfo {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .title {
      font-weight: 700;
      font-size: 18px;
      line-height: 23px;
    }

    .description {
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
    }
  }

  .duration {
    display: flex;
    gap: 10px;
  }

  .rate {
    display: flex;
    gap: 10px;
  }
}
