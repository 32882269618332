@import 'assets/theme';

.goalContainer {
  flex: 1;
  display: flex;
  font-size: 18px;
  flex-direction: column;

  .descriptionBox {
    color: $white;
    padding: 16px 14px 20px;
    min-height: 133px;
    font-weight: 600;
    background-color: $smp-light-red;

    .descriptionLabel {
      margin-bottom: $smp-standard-padding * 2;
    }

    .descriptionInput {
      color: $white;
    }
  }

  .arrowContainer {
    display: flex;
    padding: 16px 0;
    align-items: center;
    justify-content: center;

    .downArrow {
      max-width: 50px;
    }
  }

  .stepContainer {
    gap: 30px;
    display: flex;
    flex-direction: column;

    .stepBox {
      padding: 16px 12px;
      min-height: 83px;
      background-color: $smp-light-green;

      .stepInput {
        border-color: white;
        background-color: transparent;
      }

      .stepValue {
        white-space: pre-line;
      }
    }
  }
}
