@import '../../assets/theme/index';

.container {
  display: grid;
  grid-template-rows: 80px 1fr 50px;
  grid-template-areas:
    'header'
    'main'
    'footer';
  height: 100vh;
  flex-direction: column;

  @media (max-height: $medium-small-screen-min-height) {
    grid-template-rows: 60px 1fr 50px;
  }

  .header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    max-width: $large-screen-min-width;
    margin: 0 auto;
  }
}
