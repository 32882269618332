@import 'SomeoneHealth/assets/theme';

.container {
  height: max-content;
  position: relative;
  z-index: 1;
  background-size: cover;
  min-height: 440px;
  max-height: 440px;
  background-image: url('~SomeoneHealth/assets/images/background/practitioner-profile-header.png');
  background-position: top;
  background-repeat: no-repeat;

  .content {
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  }
}
